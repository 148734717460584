import instance from "../../axiosInstance";

export const apiGetAllTask = async (param) => {
  try {
    return instance.get(`/api/task`, {
      params: param,
    });
  } catch (error) {
    console.log("error", error);
  }
};

export const apiGetAllTaskForAssignable = async (param) => {
  try {
    return instance.get(`/api/all-task`, {
      params: param,
    });
  } catch (error) {
    console.log("error", error);
  }
};

export const apiGetTaskById = async (id, admin_role) => {
  try {
    return instance.get(`/api/task/${id}`, {
      params: { admin_role: admin_role },
    });
  } catch (error) {
    console.log("error", error);
  }
};

export const apiCreateTask = async (data) => {
  try {
    return instance.post(`/api/task`, data);
  } catch (error) {
    console.log("error", error);
  }
};

export const apiAssignTask = async (data) => {
  try {
    return instance.post(`/api/assign-task`, data);
  } catch (error) {
    console.log("error", error);
  }
};

export const apiEditTask = async (id, data) => {
  try {
    return instance.patch(`/api/task/${id}`, data);
  } catch (error) {
    console.log("error", error);
  }
};

export const apiUpdateStatusTask = async (id, data) => {
  try {
    return instance.patch(`/api/update-status-task/${id}`, data);
  } catch (error) {
    console.log("error", error);
  }
};
export const apiGetAllTaskGroup = async (param) => {
  try {
    return instance.get(`/api/task-group`, {
      params: param,
    });
  } catch (error) {
    console.log("error", error);
  }
};

export const apiCreateTaskGroup = async (data) => {
  try {
    return instance.post(`/api/task-group`, data);
  } catch (error) {
    console.log("error", error);
  }
};
export const apiEditTaskGroup = async (id, data) => {
  try {
    return instance.patch(`/api/task-group/${id}`, data);
  } catch (error) {
    console.log("error", error);
  }
};

export const exportTask = async (params) => {
  return await instance.get(`api/task-xlsx`, {
    params: params,
    responseType: "blob",
  });
};
