// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ModalAddTarget_modalStyle__QkIAi {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  min-width: 400px;
  max-height: 70vh;
  padding: 25px;
  /* border: 2px solid #000; */
}

.ModalAddTarget_modalStyle__QkIAi :focus-visible {
  outline: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/dashboard/ModalAddTarget.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,cAAc;EACd,gBAAgB;EAChB,gBAAgB;EAChB,aAAa;EACb,4BAA4B;AAC9B;;AAEA;EACE,aAAa;AACf","sourcesContent":[".modalStyle {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  background-color: white;\n  border-radius: 20px;\n  display: flex;\n  flex-direction: column;\n  overflow: auto;\n  min-width: 400px;\n  max-height: 70vh;\n  padding: 25px;\n  /* border: 2px solid #000; */\n}\n\n.modalStyle :focus-visible {\n  outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalStyle": `ModalAddTarget_modalStyle__QkIAi`
};
export default ___CSS_LOADER_EXPORT___;
