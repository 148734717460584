import { UploadFile } from "@mui/icons-material";
import { Box, Container, FormControl, Grid } from "@mui/material";
import { notification } from "antd";
import AdvanceSearchButton from "components/button/AdvanceSearchButton";
import FloatingButton from "components/button/FloatingButton";
import SearchButton from "components/button/SearchButton";
import SelectRowValue from "components/button/SelectRowValue";
import FieldDateRangePicker from "components/field/FieldDateRangePicker";
import FieldInput from "components/field/FieldInput";
import FieldSelect from "components/field/FieldSelect";
import FieldUpload from "components/field/FieldUpload";
import SubMenu from "components/layout/SubMenu.js";
import AlertModal from "components/modal/AlertModal";
import FormModal from "components/modal/FormModal";
import FinanceTable from "components/table/FinanceTable";
import * as moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  createProjectCarrierProgression,
  getProjectCarrierProgression,
} from "utils/api/project/project-carrier-progression";
import { ProjectCarrierProgression } from "utils/enum";
import helper from "utils/helper";

const ProjectCarrierProgress = () => {
  const navigate = useNavigate();
  const { project_id, user_id, user_code, profile_id, project_code } =
    useParams();

  const [isBusy, setIsBusy] = useState(false);
  const [errorAlert, setErrorAlert] = useState({ open: false, desc: "" });

  const [formSearch, setFormSearch] = useState({
    sortBy: "created_at",
    dateFrom: moment().subtract(29, "days").format("YYYY-MM-DD"),
    dateTo: moment().format("YYYY-MM-DD"),
    progressionType: "ALL",
    active: "ALL",
  });
  const [formSearchOld, setFormSearchOld] = useState({});
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 5,
    total: 0,
  });
  const [items, setItems] = useState([]);
  const [openModal, setopenModal] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [formUpdate, setFormUpdate] = useState({
    progressionType: "",
    file: "",
    remark: "",
  });
  const [error, setError] = useState({
    progressionType: false,
    file: false,
  });

  const subMenu_pathbackward = `/user/${user_id}/${user_code}/profile/${profile_id}/project`;

  const subMenu_menus = [
    {
      label: "รายละเอียดโครงการ",
      path: `/user/${user_id}/${user_code}/profile/${profile_id}/project/detail/${project_id}`,
    },
    {
      label: "แม่แบบงาน",
      path: `/user/${user_id}/${user_code}/profile/${profile_id}/project/${project_id}/${project_code}/template`,
    },
    {
      label: "ควาามคืบหน้าโครงการ",
      path: `/user/${user_id}/${user_code}/profile/${profile_id}/project/${project_id}/${project_code}/project-progression`,
    },
    {
      label: "ความคืบหน้าในการหาผู้ขนส่ง",
      path: `/user/${user_id}/${user_code}/profile/${profile_id}/project/${project_id}/${project_code}/carrier-progression`,
      active: true,
    },
    {
      label: "ผู้ขนส่งในโครงการ",
      path: `/user/${user_id}/${user_code}/profile/${profile_id}/project/detail/${project_id}/${project_code}/carrier-in-project`,
    },
    {
      label: "ผู้รับผิดชอบโครงการ",
      path: `/user/${user_id}/${user_code}/profile/${profile_id}/project/detail/${project_id}/${project_code}/project-admin`,
    },
  ];

  const subMenu_breadcrumbs = [
    {
      label: "ข้อมูลผู้ใช้งาน",
      path: "/user",
    },
    {
      label: `${user_code}`,
      path: "",
    },
    {
      label: "ข้อมูลโครงการ",
      path: "",
    },
    {
      label: `${project_code}`,
      path: "",
    },
    {
      label: "ความคืบหน้าในการหาผู้ขนส่ง",
      path: "",
    },
  ];

  const ProgressionOptions = [
    { value: "ALL", name: "ทั้งหมด" },
    ...ProjectCarrierProgression,
  ];

  useEffect(() => {
    handleSearch();
  }, []);

  const handleOnChangeSearch = (e, key) => {
    if (key === "date") {
      setFormSearch({ ...formSearch, dateFrom: e[0], dateTo: e[1] });
    } else {
      setFormSearch({ ...formSearch, [key]: e });
    }
  };

  const handleOnReset = () => {
    setFormSearch({
      ...formSearch,
      progressionType: "ALL",
      active: "ALL",
    });
  };

  const handleSearch = async (searchByPage = false, page = 1) => {
    setIsBusy(true);
    const formdata = searchByPage ? formSearchOld : formSearch;
    setFormSearchOld(formdata);
    let queryParams = {
      dateFrom: moment(formdata.dateFrom).toISOString(),
      dateTo: moment(formdata.dateTo).toISOString(),
      progressionType:
        formdata.progressionType !== "ALL" ? formdata.progressionType : "",
      active: formdata.active !== "ALL" ? formdata.active : "",
      sort_field: [formdata.sortBy],
      sort_order: [-1],
      page: page,
      per_page: pagination.per_page,
    };
    queryParams = helper.filteredParams(queryParams);
    try {
      const response = await getProjectCarrierProgression(
        project_id,
        queryParams
      );
      const { result, total, page, per_page } = response.data.data;
      const resultWithKey = result.map((item) => {
        const searchProgression = ProjectCarrierProgression.find(
          (option) => option.value === item.progression_type
        );
        const txtProgression = searchProgression.name;
        return {
          ...item,
          key: item.id,
          progression_type: txtProgression,
        };
      });
      setItems(resultWithKey);
      setPagination({
        page: page,
        per_page: per_page,
        total: total,
      });
      setIsBusy(false);
    } catch (error) {
      openNotificationWithIcon(
        "error",
        "Notification Error",
        error.response.data.description
      );
    }
  };

  const handlePagination = (p) => {
    handleSearch(true, p.page);
  };

  const handleOnDoubleClickRow = (key) => {
    navigate(
      `/user/${user_id}/${user_code}/profile/${profile_id}/project/${project_id}/${project_code}/carrier-progression/${key}`
    );
  };

  const renderFormAdvSearch = () => {
    return (
      <>
        <form autoComplete="off">
          <FormControl fullWidth>
            <FieldSelect
              className="w-100"
              name={`กรองและจัดเรียงตาม`}
              options={[
                { value: "created_at", name: "วันที่สร้างรายการล่าสุด" },
              ]}
              value={formSearch.sortBy}
              onChange={(e) => handleOnChangeSearch(e, "sortBy")}
            />
          </FormControl>
          <FieldDateRangePicker
            className="FieldDateRangePicker-drawer w-100"
            popupClassName="FieldDateRangePicker-drawer-popup"
            name={`วันเริ่มต้น - วันสิ้นสุด`}
            value={{
              start_date: formSearch.dateFrom,
              end_date: formSearch.dateTo,
            }}
            onChange={(e) => handleOnChangeSearch(e, "date")}
            style={{ marginBottom: "23px" }}
          />
          <FormControl fullWidth>
            <FieldSelect
              className="w-100"
              name={`สถานะความถืบหน้าโครงการ`}
              options={ProgressionOptions}
              value={formSearch.progressionType}
              onChange={(e) => handleOnChangeSearch(e, "progressionType")}
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldSelect
              className="w-100"
              name={`สถานะงาน`}
              options={[
                { value: "ALL", name: "ทั้งหมด" },
                { value: 1, name: "ใช้งาน" },
                { value: 0, name: "ระงับใช้งาน" },
              ]}
              value={formSearch.active}
              onChange={(e) => handleOnChangeSearch(e, "active")}
            />
          </FormControl>
        </form>
      </>
    );
  };

  const renderFilterBar = () => {
    return (
      <Box
        sx={{
          borderRadius: "10px",
          backgroundColor: "white",
          padding: "25px",
          boxShadow: `0px 4px 4px 0px rgba(140, 163, 186, 0.20)`,
          zIndex: 2,
          flexGrow: 1,
        }}
      >
        <Grid container rowSpacing={2} justifyContent="center">
          <Grid
            item
            sx={{ paddingRight: "20px" }}
            xs={12}
            sm={6}
            md={6}
            lg={2.5}
          >
            <FieldSelect
              className="w-100"
              name={`กรองและจัดเรียงตาม`}
              options={[
                { value: "created_at", name: "วันที่สร้างรายการล่าสุด" },
              ]}
              value={formSearch.sortBy}
              onChange={(e) => handleOnChangeSearch(e, "sortBy")}
              closePaddingBottom
            />
          </Grid>
          <Grid
            item
            sx={{ paddingRight: "20px" }}
            xs={12}
            sm={6}
            md={6}
            lg={2.5}
          >
            <FieldDateRangePicker
              className="w-100"
              name={`วันเริ่มต้น - วันสิ้นสุด`}
              value={{
                start_date: formSearch.dateFrom,
                end_date: formSearch.dateTo,
              }}
              onChange={(e) => handleOnChangeSearch(e, "date")}
            />
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              alignItems: "center",
            }}
            justifyContent="center"
            xs={12}
            sm={6}
            md={6}
            lg={2}
          >
            <SearchButton onClick={handleSearch} />
            <FormControl>
              <SelectRowValue
                onSelect={(e) => {
                  setPagination({ ...pagination, per_page: e });
                }}
              />
              {/* <FieldSelect
                className="w-100"
                name="จำนวนแถว"
                options={[
                  { value: 5, name: "5" },
                  { value: 10, name: "10" },
                  { value: 50, name: "50" },
                  { value: 100, name: "100" },
                ]}
                value={pagination.per_page}
                onChange={(e) => setPagination({ ...pagination, per_page: e })}
              /> */}
            </FormControl>
            <AdvanceSearchButton
              onReset={handleOnReset}
              onSearch={handleSearch}
              value={formSearch}
            >
              {renderFormAdvSearch()}
            </AdvanceSearchButton>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const renderTable = () => {
    const fields = [
      {
        key: "created_at",
        label: "วันที่สร้างรายการ",
        render: "date",
        tdclass: "text-center",
      },
      {
        key: "progression_type",
        label: "สถานะความคืบหน้าในการหาผู้ขนส่ง",
        tdclass: "text-center",
      },
      {
        key: "updated_name",
        label: "ผู้อัพเดทสถานะ",
        tdclass: "text-center",
      },
      {
        key: "active",
        label: "สถานะใช้งาน",
        render: "active",
        tdclass: "text-center",
      },
    ];
    return (
      <>
        <FinanceTable
          items={items}
          setItems={setItems}
          fields={fields}
          totalRows={items.length}
          pagination={pagination}
          setPagination={handlePagination}
          isBusy={isBusy}
          onClickRow={handleOnDoubleClickRow}
        />
      </>
    );
  };

  const handleAddButton = () => {
    setFormUpdate({
      progressionType: "",
      file: "",
      remark: "",
    });
    setopenModal(true);
  };

  const handleOnChangeFormUpdate = (e, key) => {
    let value = e;
    if (key === "remark") {
      value = e.target.value;
    }
    setFormUpdate({ ...formUpdate, [key]: value });
  };

  const handleConfirmModal = async () => {
    const insertData = {
      progressionType: formUpdate.progressionType,
      file: formUpdate.file,
      remark: formUpdate.remark,
    };
    const newError = {};
    Object.entries(insertData).forEach(([key, value]) => {
      if (["progressionType", "file"].includes(key)) {
        if (
          value === undefined ||
          value === null ||
          value === "" ||
          value === "0.00"
        ) {
          newError[key] = true;
        } else {
          newError[key] = false;
        }
      }
    });
    const maximumSize = 10 * 1024 * 1024;
    if (insertData.file.size > maximumSize) {
      newError.file = true;
    }
    const fileType = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/pdf",
    ];
    if (!fileType.includes(insertData.file.type)) {
      newError.file = true;
    }
    setError(newError);
    if (Object.values(newError).every((value) => !value)) {
      try {
        const response = await createProjectCarrierProgression(
          project_id,
          insertData
        );
        if (response) {
          openNotificationWithIcon(
            "success",
            "Notification Success",
            response.data.description
          );
          handleSearch();
          setopenModal(false);
          return true;
        }
      } catch (error) {
        console.log(error);
        openNotificationWithIcon(
          "error",
          "Notification Error",
          "เกิดข้อผิดพลาดบางอย่าง"
        );
        return false;
      }
    } else {
      openNotificationWithIcon(
        "error",
        "Notification Error",
        "กรุณากรอกข้อมูลให้ครบถ้วน"
      );
    }
    return false;
  };

  const renderModal = () => {
    return (
      <FormModal
        open={openModal}
        title="ความคืบหน้าในการหาผู้ขนส่ง"
        disabledCancelBtn={disabledBtn}
        disabledComfirmBtn={disabledBtn}
        setIsModalOpen={setopenModal}
        onConfirm={handleConfirmModal}
      >
        <Box component="div" sx={{ width: "400px" }}>
          <form autoComplete="off">
            <FormControl fullWidth>
              <FieldSelect
                className="w-100"
                name={`สถานะความถืบหน้าโครงการ`}
                options={ProjectCarrierProgression}
                value={formUpdate.progressionType}
                error={error.progressionType}
                helperText={error.actiprogressionTypeve ? "จำเป็นต้องระบุ" : ""}
                onChange={(e) => handleOnChangeFormUpdate(e, "progressionType")}
              />
            </FormControl>
            <FormControl fullWidth>
              <FieldUpload
                name={"ไฟล์ความถืบหน้าโครงการ*"}
                icon={<UploadFile />}
                value={formUpdate.file}
                accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/pdf"
                onChange={(e) => handleOnChangeFormUpdate(e, "file")}
                error={error.file}
                textError={
                  error.file
                    ? "อัพโหลดไฟล์นามสกุลไฟล์ XLSX และ PDF เท่านั้น และขนาดไม่เกิน 10 MB"
                    : ""
                }
              />
            </FormControl>
            <FormControl fullWidth>
              <FieldInput
                className="w-100"
                label="หมายเหตุ"
                value={formUpdate.remark}
                onChange={(e) => handleOnChangeFormUpdate(e, "remark")}
              />
            </FormControl>
          </form>
        </Box>
      </FormModal>
    );
  };

  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type, message, description) => {
    api[type]({
      message: message,
      description: description,
    });
  };

  return (
    <div className="project-progression">
      {contextHolder}
      <Container>
        {errorAlert.open === true && (
          <AlertModal
            open={errorAlert.open}
            title="เกิดข้อผิดพลาด"
            description={errorAlert.desc}
            onClose={() => {
              setErrorAlert({
                open: false,
                desc: "",
              });
              navigate(-1);
            }}
          />
        )}
        <SubMenu
          pathbackward={subMenu_pathbackward}
          menus={subMenu_menus}
          breadcrumbs={subMenu_breadcrumbs}
        />
        {renderFilterBar()}
        {renderTable()}
        {renderModal()}
        <FloatingButton
          button_type="add"
          onClick={() => {
            handleAddButton();
          }}
        />
      </Container>
    </div>
  );
};

export default ProjectCarrierProgress;
