import { Box, Container, FormControl, Grid } from "@mui/material";
import SearchButton from "components/button/SearchButton";
import FieldDateRangePicker from "components/field/FieldDateRangePicker";
import FieldSearch from "components/field/FieldSearch";
import FieldSelect from "components/field/FieldSelect";
import SubMenu from "components/layout/SubMenu.js";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import "../finance.css";
import { subMenuTradeFinance } from "utils/submenuoptions";
import FieldDatePicker from "components/field/FieldDatePicker";
import { notification } from "antd";
import FinanceTable from "components/table/FinanceTable";
import helper from "utils/helper";
import FieldInput from "components/field/FieldInput";
import FieldNumber from "components/field/FieldNumber";
import { createInvoice, getUnpaid } from "utils/api/trade-finance/carrier";
import UpdateStatusButton from "components/button/finance/UpdateStatusButton";
import * as currency from "currency.js";
import FieldUpload from "components/field/FieldUpload";
import { UploadFile } from "@mui/icons-material";
import SelectRowValue from "components/button/SelectRowValue";
import AuthContext from "components/context/AuthContext";

const TradeSellerUnpiad = () => {
  const { adminAuth } = useContext(AuthContext);
  //form filter
  const [formSearch, setFormSearch] = useState({
    sortBy: "sent_date",
    dateFrom: moment().subtract(365, "days").format("YYYY-MM-DD 00:00:00"),
    dateTo: moment().add(365, "days").format("YYYY-MM-DD 23:59:59"),
    filterBy: "order_code",
    filter: "",
    profile_type: "ALL",
    payment_type: "ALL",
    work_status: "ALL",
  });
  const [formSearchOld, setFormSearchOld] = useState({});
  //datatable
  const [selectAll, setSelectAll] = useState(false);
  const [isBusy, setIsBusy] = useState(true);
  const [rowSelected, setRowSelected] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 5,
    total: 0,
  });
  const [items, setItems] = useState([]);
  const [disabledSelectAll, setDisabledSelectAll] = useState(false);
  //updatestatus modal
  const [updateStatusForm, setUpdateStatusForm] = useState({
    actual_date: moment().format("YYYY-MM-DD kk:mm"),
    trade_transport_ids: [],
    amount_per_trip: 0,
    vat_per_trip: 0,
    total_per_trip: 0,
    decimal: 2,
    file: "",
    receipt_voucher_code: "",
  });
  const [error, setError] = useState({
    actual_date: false,
    trade_transport_ids: false,
    amount_per_trip: false,
    vat_per_trip: false,
    total_per_trip: false,
    decimal: false,
    receipt_voucher_code: false,
  });

  const [subMenu_menus, setSubMenuMenus] = useState([]);
  const [subMenu_breadcrumbs, setSubMenuBreadcrumbs] = useState([]);
  const [setSubmenuDone, setSetSubmenuDone] = useState(false);

  const setActiveSubMenu = (index) => {
    const updatedMenus = subMenu_menus.map((menu, idx) => {
      return { ...menu, active: idx === index };
    });
    setSubMenuMenus(updatedMenus);
  };

  useEffect(() => {
    setSubMenuBreadcrumbs([
      {
        label: "จัดการการซื้อขาย",
        path: null,
      },
      {
        label: "ข้อมูลการเงินผู้ขนส่ง",
        path: null,
      },
      {
        label: "รอตั้งเบิก",
        path: null,
      },
    ]);
    handleSearch();
  }, []);

  useEffect(() => {
    if (adminAuth) {
      const filteredMenus = subMenuTradeFinance.filter((menu) => {
        return (
          !menu.fix_role || menu.fix_role.includes(adminAuth.admin_role_type)
        );
      });
      setSubMenuMenus(filteredMenus);
      setSetSubmenuDone(true);
    }
  }, [adminAuth]);

  useEffect(() => {
    if (setSubmenuDone) {
      setActiveSubMenu(14);
      setSetSubmenuDone(false);
    }
  }, [setSubmenuDone]);

  useEffect(() => {
    if (rowSelected.length > 0) {
      const customer_id = rowSelected[0].customer_id;
      const project_name = rowSelected[0].project_name;
      const order_code = rowSelected[0].order_code;
      setItems((prevItems) =>
        prevItems.map((item) => {
          if (
            item.customer_id !== customer_id ||
            item.project_name !== project_name ||
            item.order_code !== order_code
          ) {
            // if (item.key !== rowSelected[0].key) {
            //เปลี่นนเป็นให้เลือกได้รายการเดียว
            return { ...item, rowDisabled: true };
          } else {
            return item;
          }
        })
      );
    } else {
      setItems((prevItems) =>
        prevItems.map((item) => {
          return { ...item, rowDisabled: false };
        })
      );
    }
  }, [rowSelected]);

  useEffect(() => {
    handleSearch();
  }, [pagination.per_page]);

  const handleOnChangeSearch = (e, key) => {
    if (key === "date") {
      setFormSearch({ ...formSearch, dateFrom: e[0], dateTo: e[1] });
    } else {
      setFormSearch({ ...formSearch, [key]: e });
    }
  };

  const handleSearch = async (
    resetCheckBox = false,
    searchByPage = false,
    page = 1
  ) => {
    setIsBusy(true);
    setSelectAll(false);
    setDisabledSelectAll(false);
    const formdata = searchByPage ? formSearchOld : formSearch;
    setFormSearchOld(formdata);
    let queryParams = {
      dateFrom: moment(formdata.dateFrom).toISOString(),
      dateTo: moment(formdata.dateTo).toISOString(),
      sort_field: [formdata.sortBy],
      sort_order: [-1],
      page: page,
      per_page: pagination.per_page,
    };
    if (formdata.filterBy && formdata.filter) {
      queryParams = {
        ...queryParams,
        [formdata.filterBy]: formdata.filter.trim(),
      };
    }
    queryParams = helper.filteredParams(queryParams);
    try {
      const response = await getUnpaid(queryParams);
      if (response) {
        const { result, total, page, per_page } = response.data.data;
        const uniqueProjectIds = [
          ...new Set(result.map((item) => item.customer_id)),
        ];

        const updatedResult = result.map((item) => {
          let rowIsActive = false;
          let rowDisabled = false;

          if (!resetCheckBox) {
            if (rowSelected.length > 0) {
              const customer_id = rowSelected[0].customer_id;
              rowIsActive = rowSelected.some((selectedItem) => {
                return selectedItem.key === item.key;
              });
              rowDisabled = customer_id !== item.customer_id;
              // rowDisabled = rowSelected[0].key !== item.key;
            }
          }

          return {
            ...item,
            rowIsActive: rowIsActive,
            rowDisabled: rowDisabled,
          };
        });

        setItems(updatedResult);
        setPagination({
          page: page,
          per_page: per_page,
          total: total,
        });
        setIsBusy(false);
        setDisabledSelectAll(uniqueProjectIds.length > 1);
      }
    } catch (error) {
      openNotificationWithIcon(
        "error",
        "Notification Error",
        "error.response.data.description"
      );
    }
  };

  const handleUpdatestatusFormChange = (name, event) => {
    if (["actual_date", "bank_id", "file"].includes(name)) {
      const value = event;
      setUpdateStatusForm((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else if (name === "decimal") {
      const value = event;
      const groupdata = rowSelected.reduce(
        (result, val) => {
          Object.keys(val).forEach((key) => {
            if (key === "key") {
              result["trade_transport_ids"].push(val["key"]);
            } else if (["carrier_freight_before_tax"].includes(key)) {
              result["amount_per_trip"] = currency(
                result["amount_per_trip"]
              ).add(val[key]).value;
            }
          });
          return result;
        },
        {
          trade_transport_ids: [],
          amount_per_trip: 0,
        }
      );
      const vat = currency(groupdata.amount_per_trip).multiply(0.01).value;
      const total = currency(groupdata.amount_per_trip).subtract(vat).value;
      const amount_per_trip = helper.formatNumeric(
        groupdata.amount_per_trip,
        "display",
        value
      );
      const vat_per_trip = helper.formatNumeric(vat, "display", value);
      const total_per_trip = helper.formatNumeric(total, "display", value);
      setUpdateStatusForm((prevData) => ({
        ...prevData,
        [name]: value,
        amount_per_trip: amount_per_trip,
        vat_per_trip: vat_per_trip,
        total_per_trip: total_per_trip,
      }));
    } else {
      const value = helper.sanitizeInput(event.target.value);
      if (name === "vat_per_trip") {
        const amount = updateStatusForm.amount_per_trip;
        setUpdateStatusForm((prevData) => ({
          ...prevData,
          [name]: value,
          total_per_trip: currency(amount, {
            precision: updateStatusForm.decimal,
            symbol: "",
          })
            .subtract(value)
            .format(),
        }));
      } else {
        setUpdateStatusForm((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    }
  };

  const handleOnclickUpdateStatusButton = async () => {
    if (rowSelected.length > 0) {
      const groupdata = rowSelected.reduce(
        (result, val) => {
          Object.keys(val).forEach((key) => {
            if (key === "key") {
              result["trade_transport_ids"].push(val["key"]);
            } else if (["carrier_freight_before_tax"].includes(key)) {
              result["amount_per_trip"] = currency(
                result["amount_per_trip"]
              ).add(val[key]).value;
            }
          });
          return result;
        },
        {
          trade_transport_ids: [],
          amount_per_trip: 0,
        }
      );
      const vat = currency(groupdata.amount_per_trip).multiply(0.01).value;
      const total = currency(groupdata.amount_per_trip).subtract(vat).value;
      const amount_per_trip = helper.formatNumeric(
        groupdata.amount_per_trip,
        "display"
      );
      const vat_per_trip = helper.formatNumeric(vat, "display");
      const total_per_trip = helper.formatNumeric(total, "display");
      console.log(groupdata);
      setUpdateStatusForm({
        actual_date: moment().format("YYYY-MM-DD kk:mm"),
        trade_transport_ids: groupdata.trade_transport_ids,
        amount_per_trip: amount_per_trip,
        vat_per_trip: vat_per_trip,
        total_per_trip: total_per_trip,
        decimal: 2,
        file: "",
        receipt_voucher_code: "",
      });
      return true;
    } else {
      openNotificationWithIcon(
        "error",
        "Notification Error",
        "กรุณาเลือกงานมากกว่า 1 งาน"
      );
      return false;
    }
  };

  const handleSubmitUpdateStatus = async () => {
    const insertData = {
      trade_transport_ids: updateStatusForm.trade_transport_ids,
      actual_date: updateStatusForm.actual_date,
      amount_per_trip: currency(updateStatusForm.amount_per_trip, {
        precision: updateStatusForm.decimal,
      }).value,
      vat_per_trip: currency(updateStatusForm.vat_per_trip, {
        precision: updateStatusForm.decimal,
      }).value,
      total_per_trip: currency(updateStatusForm.total_per_trip, {
        precision: updateStatusForm.decimal,
      }).value,
      decimal: updateStatusForm.decimal,
      file: updateStatusForm.file,
      receipt_voucher_code: updateStatusForm.receipt_voucher_code,
    };
    const newError = {};
    Object.entries(insertData).forEach(([key, value]) => {
      if (
        [
          "actual_date",
          "trade_transport_ids",
          "amount_per_trip",
          "vat_per_trip",
          "total_per_trip",
          "decimal",
          "receipt_voucher_code",
        ].includes(key)
      ) {
        if (
          value === undefined ||
          value === null ||
          value === "" ||
          value === 0
        ) {
          newError[key] = true;
        } else {
          newError[key] = false;
        }
      }
    });
    const check_actual_date = moment(
      updateStatusForm.actual_date,
      "YYYY-MM-DD kk:mm"
    ).isValid();
    newError.actual_date = !check_actual_date;
    if (Object.values(newError).every((value) => !value)) {
      try {
        const response = await createInvoice(insertData);
        if (response) {
          openNotificationWithIcon(
            "success",
            "Notification Success",
            response.data.description
          );
          setRowSelected([]);
          handleSearch(true);
          return true;
        }
      } catch (error) {
        console.log(error);
        openNotificationWithIcon(
          "error",
          "Notification Error",
          "เกิดข้อผิดพลาดบางอย่าง"
        );
        return false;
      }
    } else {
      setError(newError);
      openNotificationWithIcon(
        "error",
        "Notification Error",
        "กรุณากรอกข้อมูลให้ครบถ้วน"
      );
    }
    return false;
  };

  const handlePagination = (p) => {
    handleSearch(false, true, p.page);
  };

  const renderFilterBar = () => {
    return (
      <Box
        sx={{
          borderRadius: "10px",
          backgroundColor: "white",
          padding: "25px",
          boxShadow: `0px 4px 4px 0px rgba(140, 163, 186, 0.20)`,
          zIndex: 2,
          flexGrow: 1,
        }}
      >
        <Grid container rowSpacing={2} justifyContent="center">
          <Grid
            item
            sx={{ paddingRight: "20px" }}
            xs={12}
            sm={6}
            md={6}
            lg={2.5}
          >
            <FieldSelect
              className="w-100"
              name={`กรองและจัดเรียงตาม`}
              options={[{ value: "sent_date", name: "กำหนดวันส่ง" }]}
              value={formSearch.sortBy}
              onChange={(e) => handleOnChangeSearch(e, "sortBy")}
              closePaddingBottom
            />
          </Grid>
          <Grid
            item
            sx={{ paddingRight: "20px" }}
            xs={12}
            sm={6}
            md={6}
            lg={2.5}
          >
            <FieldDateRangePicker
              className="w-100"
              name={`วันเริ่มต้น - วันสิ้นสุด`}
              value={{
                start_date: formSearch.dateFrom,
                end_date: formSearch.dateTo,
              }}
              onChange={(e) => handleOnChangeSearch(e, "date")}
            />
          </Grid>
          <Grid
            item
            sx={{ paddingRight: "20px" }}
            xs={12}
            sm={6}
            md={6}
            lg={2.5}
          >
            <FieldSelect
              className="w-100"
              name={`กรองตาม`}
              options={[
                { value: "order_code", name: "เลขที่คําสั่งซื้อ" },
                { value: "id", name: "ไอดี" },
              ]}
              value={formSearch.filterBy}
              onChange={(e) => handleOnChangeSearch(e, "filterBy")}
              closePaddingBottom
            />
          </Grid>
          <Grid
            item
            sx={{ paddingRight: "20px" }}
            xs={12}
            sm={6}
            md={6}
            lg={2.5}
          >
            <FieldSearch
              className="w-100"
              value={formSearch.filter}
              onChange={(e) => handleOnChangeSearch(e, "filter")}
            />
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              alignItems: "center",
            }}
            justifyContent="center"
            xs={12}
            sm={6}
            md={6}
            lg={2}
          >
            <SearchButton onClick={handleSearch} />
            <UpdateStatusButton
              title="ตั้งเบิก"
              onClick={handleOnclickUpdateStatusButton}
              onConfirm={handleSubmitUpdateStatus}
            >
              {renderFormUpdateStatus()}
            </UpdateStatusButton>
            <FormControl>
              <SelectRowValue
                onSelect={(val) => {
                  setPagination({ ...pagination, per_page: val });
                }}
              />
              {/* <FieldSelect
                className="w-100"
                name="จำนวนแถว"
                options={[
                  { value: 5, name: "5" },
                  { value: 10, name: "10" },
                  { value: 50, name: "50" },
                  { value: 100, name: "100" },
                ]}
                value={pagination.per_page}
                onChange={(e) => setPagination({ ...pagination, per_page: e })}
              /> */}
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const renderFormUpdateStatus = () => {
    return (
      <Box sx={{ width: "360px" }}>
        <form className="form-updatestatus" autoComplete="off">
          <FormControl fullWidth>
            <FieldDatePicker
              className="w-100"
              label="วันเวลาที่ต้องการตั้งเบิก *"
              subLabel="(ค่าเริ่มต้น: วันและเวลาปัจจุบัน)"
              value={updateStatusForm.actual_date}
              onChange={(e) => handleUpdatestatusFormChange("actual_date", e)}
              showTime
              error={error.actual_date}
              helperText={error.actual_date ? "จำเป็นต้องระบุ" : ""}
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldInput
              className="w-100"
              label="รวมจำนวนคำสั่ง"
              subLabel="(ผลรวมจากการเลือก)"
              value={updateStatusForm.trade_transport_ids.length}
              readOnly
              error={error.trade_transport_ids}
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldNumber
              className="w-100"
              label="รวมราคาผู้ขนส่งก่อนหักภาษี"
              subLabel="(ผลรวมจากการเลือก)"
              value={updateStatusForm.amount_per_trip}
              type="float"
              precision={updateStatusForm.decimal}
              readOnly
              error={error.amount_per_trip}
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldNumber
              className="w-100"
              label="รวมภาษีหัก ณ ที่จ่าย"
              subLabel="(ผลรวมจากการเลือก)"
              value={updateStatusForm.vat_per_trip}
              onChange={(e) => handleUpdatestatusFormChange("vat_per_trip", e)}
              type="float"
              precision={updateStatusForm.decimal}
              error={error.vat_per_trip}
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldNumber
              className="w-100"
              label="รวมราคาผู้ขนส่งสุทธิ"
              subLabel="(ผลรวมจากการเลือก)"
              value={updateStatusForm.total_per_trip}
              type="float"
              precision={updateStatusForm.decimal}
              readOnly
              error={error.total_per_trip}
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldSelect
              className="w-100"
              name="จำนวนทศนิยม"
              options={[
                { value: 2, name: "2" },
                { value: 4, name: "4" },
                { value: 6, name: "6" },
              ]}
              value={updateStatusForm.decimal}
              onChange={(e) => handleUpdatestatusFormChange("decimal", e)}
              error={error.decimal}
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldUpload
              name={"ใบแจ้งหนี้จากผู้ขาย*"}
              icon={<UploadFile />}
              value={updateStatusForm.file}
              accept="image/png, image/jpeg, application/pdf"
              onChange={(e) => handleUpdatestatusFormChange("file", e)}
              error={error.file}
              helperText={error.file ? "จำเป็นต้องระบุ" : ""}
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldInput
              className="w-100"
              label="เลขที่ใบแจ้งหนี้จากผู้ขาย*"
              value={updateStatusForm.receipt_voucher_code}
              error={error.receipt_voucher_code}
              onChange={(e) =>
                handleUpdatestatusFormChange("receipt_voucher_code", e)
              }
            />
          </FormControl>
        </form>
      </Box>
    );
  };

  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type, message, description) => {
    api[type]({
      message: message,
      description: description,
    });
  };

  const renderTable = () => {
    const fields = [
      {
        key: "order_code",
        label: "เลขที่คําสั่งซื้อ",
        tdclass: "text-center",
        render: "key",
      },
      {
        key: "customer_name",
        label: "ผู้ซื้อ",
      },
      {
        key: "project_name",
        label: "ชื่อโครงการ",
      },
      {
        key: "sent_date",
        label: "กำหนดวันส่ง",
        tdclass: "text-center",
        render: "date",
      },
      {
        key: "destination_name",
        label: "ชื่อจุดส่ง",
        tdclass: "text-center",
      },
      {
        key: "trade_jobber",
        label: "ผู้ขาย",
        tdclass: "text-center",
      },
      {
        key: "trade_carrier_jobber",
        label: "ผู้ขนส่ง",
        tdclass: "text-center",
      },
      {
        key: "amount_per_trip",
        label: "จำนวนสินค้าที่ขาย",
        tdclass: "text-end",
        render: "currency",
      },
      {
        key: "buy_per_trip",
        label: "ราคาสินค้าที่ซื้อ (บาท)",
        tdclass: "text-end",
        render: "currency",
      },
      {
        key: "sell_per_trip",
        label: "ราคาสินค้าที่ขาย (บาท)",
        tdclass: "text-end",
        render: "currency",
      },
      {
        key: "carrier_freight_before_tax",
        label: "ราคาขนส่ง(บาท)",
        tdclass: "text-end",
        render: "currency",
      },
      {
        key: "profit_bath",
        label: "กำไร (บาท)",
        tdclass: "text-end",
        render: "currency",
      },
      {
        key: "profit_percent",
        label: "กำไร (%)",
        tdclass: "text-end",
        render: "percent",
      },
    ];

    return (
      <>
        <FinanceTable
          items={items}
          setItems={setItems}
          fields={fields}
          totalRows={items.length}
          setRowSelected={setRowSelected}
          rowSelected={rowSelected}
          pagination={pagination}
          setPagination={handlePagination}
          isBusy={isBusy}
          disabledSelectAll={disabledSelectAll}
          selectAll={selectAll}
          setSelectAll={setSelectAll}
        />
      </>
    );
  };

  return (
    <div className="Finance ShipperUnpiad">
      {contextHolder}
      <Container>
        <SubMenu menus={subMenu_menus} breadcrumbs={subMenu_breadcrumbs} />
        {renderFilterBar()}
        {renderTable()}
      </Container>
    </div>
  );
};

export default TradeSellerUnpiad;
