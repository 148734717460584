import { Add, Edit, Search } from "@mui/icons-material";
import {
  Avatar,
  Container,
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material";
import FieldSelect from "components/field/FieldSelect";
import SubMenu from "components/layout/SubMenu.js";
import LoadingBackground from "components/loading/LoadingBackground";
import AlertModal from "components/modal/AlertModal";
import TableCustom from "components/table/TableCustom";
import React, { useState, useContext, useEffect } from "react";
import helper from "utils/helper";
import styles from "../TableTask.module.css";
import { useCookies } from "react-cookie";
import FieldDateRangePicker from "components/field/FieldDateRangePicker";
import SelectRowValue from "components/button/SelectRowValue";
import AuthContext from "components/context/AuthContext";
import moment from "moment";
import { apiGetAllTaskGroup } from "utils/api/task/task";
import TableActiveIcon from "components/tableActiveIcon";
import ModalTaskGroup from "./ModalTaskGroup";

const TableTaskGroup = () => {
  const { adminAuth } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [, , removeAccessToken] = useCookies(["access_token"]);
  const [allTaskGroup, setAllTaskGroup] = useState([]);
  const [errorAlert, setErrorAlert] = useState({ open: false, desc: "" });
  const [successAlert, setSuccessAlert] = useState(false);
  const [dateTo, setDateTo] = useState(moment().format("YYYY-MM-DD 23:59:59"));
  const [dateFrom, setDateFrom] = useState(
    moment().subtract(30, "days").format("YYYY-MM-DD 00:00:00")
  );
  const [openModalCreate, setOpenModalCreate] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState({
    open: false,
    data: null,
  });
  const [filter, setFilter] = useState({
    sort_by: "updated_at",
    start_date: "",
    end_date: "",
    page: 1,
    per_page: 5,
    total: 0,
    search_val: "",
    search_by: "task_group_name",
  });
  const [hasPermission, setHasPermission] = useState(false);
  useEffect(() => {
    const hasP = adminAuth?.adminPermissions?.some((item) => {
      return item?.menu_permission_id === 24 && item?.visit === true;
    });
    setHasPermission(hasP);
  }, [adminAuth]);
  const subMenu = [
    {
      label: "จัดการงานตัวเอง",
      path: "/task",
    },
    {
      label: "จัดการกลุ่มงาน",
      path: "/task/task-group",
    },
    hasPermission && {
      label: "จัดการงานทั้งระบบ",
      path: "/task/all-task",
    },
  ];
  const breadcrumbs = [
    {
      label: "จัดการงารตัวเอง",
      path: null,
    },
  ];

  const columns = [
    {
      id: "created_at",
      label: "วันที่สร้างรายการ",
      align: "center",
      //   width: "13%",
      format: (value) => helper.ToConvertDateTimeForTable(value.created_at),
    },
    {
      id: "updated_at",
      label: "วันที่แก้ไขข้อมูลล่าสุด",
      align: "center",
      //   width: "13%",
      format: (value) => helper.ToConvertDateTimeForTable(value.updated_at),
    },
    {
      id: "task_group_name",
      label: "ชื่อกลุ่มงาน",
      align: "center",
    },
    {
      id: "active",
      label: "สถานะใช้งาน",
      align: "center",
      width: "10%",
      format: (value) => {
        return <TableActiveIcon active_status={value.active} />;
      },
    },
    {
      id: "action",
      label: "ทำรายการ",
      align: "center",
      format: (val) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ display: "flex", gap: "5px", paddingBottom: "5px" }}>
              <Avatar
                variant="rounded"
                sx={{
                  width: 25,
                  height: 25,
                  boxShadow: `0px 2px 2px 0px rgba(0, 0, 0, 0.15)`,
                  bgcolor: "white",
                }}
                onClick={() => setOpenModalEdit({ open: true, data: val })}
              >
                <Edit
                  sx={{
                    color: "secondary.main",
                    fontSize: 16,
                  }}
                />
              </Avatar>
            </div>
          </div>
        );
      },
    },
  ];

  const filterBar = () => {
    return (
      <div
        style={{
          borderRadius: "10px",
          backgroundColor: "white",
          padding: "25px",
          boxShadow: `0px 4px 4px 0px rgba(140, 163, 186, 0.20)`,
          zIndex: 2,
          flexGrow: 1,
        }}
      >
        <Grid container rowSpacing={2} justifyContent="center">
          <Grid className={styles.gridItem} item xs={12} sm={6} md={6} lg={2.5}>
            <FieldSelect
              name={"กรองและจัดเรียงตาม"}
              options={[
                { value: "created_at", name: "วันที่สร้างรายการ" },
                { value: "updated_at", name: "วันที่แก้ไขข้อมูลล่าสุด" },
              ]}
              style={{
                width: "-webkit-fill-available",
              }}
              defaultValue={"updated_at"}
              value={filter.sort_by}
              onChange={(val) => setFilter({ ...filter, sort_by: val })}
              closePaddingBottom={true}
            />
          </Grid>
          <Grid className={styles.gridItem} item xs={12} sm={6} md={6} lg={2.5}>
            <FieldDateRangePicker
              className="dateSelect"
              name={`วันเริ่มต้น - วันสิ้นสุด`}
              value={{
                start_date: filter?.start_date ?? dateFrom,
                end_date: filter?.end_date ?? dateTo,
              }}
              style={{ width: "-webkit-fill-available" }}
              onChange={(val) =>
                setFilter({
                  ...filter,
                  start_date: val[0],
                  end_date: val[1],
                })
              }
            />
          </Grid>
          <Grid className={styles.gridItem} item xs={12} sm={6} md={6} lg={2.5}>
            <FieldSelect
              name={"ค้นหาจาก"}
              options={[{ value: "task_group_name", name: "ชื่อกลุ่มงาน" }]}
              style={{
                width: "-webkit-fill-available",
              }}
              defaultValue={"task_group_name"}
              value={filter.search_by}
              onChange={(val) => setFilter({ ...filter, search_by: val })}
              closePaddingBottom={true}
            />
          </Grid>
          <Grid className={styles.gridItem} item xs={12} sm={6} md={6} lg={2.5}>
            <TextField
              id="search_val"
              variant="standard"
              style={{
                paddingTop: "16px",
              }}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              value={filter.search_val ? filter.search_val : ""}
              onChange={(val) =>
                setFilter({ ...filter, search_val: val.target.value })
              }
            />
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              alignItems: "center",
            }}
            justifyContent="center"
            xs={12}
            sm={6}
            md={6}
            lg={2}
          >
            <Avatar
              variant="rounded"
              sx={{
                width: "40px",
                height: "40px",
                filter: "drop-shadow(0px 4px 4px rgba(140, 163, 186, 0.2))",
                bgcolor: "white",
              }}
              onClick={() => {
                const filterTG = { ...filter, page: 1 };
                getAllTaskGroup(filterTG);
              }}
            >
              <Search sx={{ color: "secondary.main", fontSize: 35 }} />
            </Avatar>
            <SelectRowValue
              onSelect={(val) => {
                setFilter({ ...filter, per_page: val });
              }}
            />
            {/* <Avatar
              variant="rounded"
              sx={{
                width: "40px",
                height: "40px",
                filter: "drop-shadow(0px 4px 4px rgba(140, 163, 186, 0.2))",
                bgcolor: "white",
              }}
              onClick={() => setAdvanceFilter(true)}
            >
              <FilterAlt sx={{ color: "secondary.main", fontSize: 35 }} />
            </Avatar> */}
          </Grid>
        </Grid>
      </div>
    );
  };

  useEffect(() => {
    getAllTaskGroup(filter);
  }, [filter.page, filter.per_page]);
  const getAllTaskGroup = async (_filter) => {
    setLoading(true);
    await apiGetAllTaskGroup(_filter)
      .then((res) => {
        setLoading(false);
        if (res.data.code === 1000) {
          setAllTaskGroup(res.data.data);
          setFilter({ ...filter, total: res.data.total });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        if (error.response.data.code === 4106) {
          setErrorAlert({
            open: true,
            desc: error.response.data.description,
          });
        } else if (error.response.data.code === "4105") {
          removeAccessToken("access_token");
          window.location.reload();
        }
      });
  };
  return (
    <div>
      {loading && <LoadingBackground />}
      <AlertModal
        open={successAlert}
        title="บันทึก"
        description="บันทึกข้อมูลสำเร็จ"
        onClose={() => {
          setSuccessAlert(false);
          const filterTG = { ...filter, page: 1 };
          getAllTaskGroup(filterTG);
        }}
      />
      <AlertModal
        open={errorAlert.open}
        title="เกิดข้อผิดพลาด"
        description={errorAlert.desc}
        onClose={() => {
          setErrorAlert({
            open: false,
            desc: "",
          });
        }}
      />
      <Container>
        <SubMenu
          menus={subMenu.map((menu) => {
            return {
              label: menu?.label,
              path: menu?.path,
              active: menu?.label === `จัดการกลุ่มงาน` ? true : false,
            };
          })}
          breadcrumbs={breadcrumbs}
          pathbackward={"/"}
        />
        <div style={{ paddingTop: "20px" }}></div>
        {filterBar()}
        <div>
          <TableCustom
            columns={columns}
            dataSource={allTaskGroup}
            pagination={filter}
            setPagination={(val) => {
              setFilter({
                ...filter,
                ...val,
              });
            }}
            // onSelectedRow={(val) => console.log(val)}
            // href={`/user/`}
            // checkBox={true}
            hrefDisabled
          />
        </div>

        <Avatar
          sx={{
            position: "fixed",
            bottom: "35px",
            right: "97px",
            width: 100,
            height: 100,
            bgcolor: "secondary.main",
          }}
          onClick={() => setOpenModalCreate(true)}
        >
          <Add sx={{ color: "white", fontSize: "90px" }} />
        </Avatar>

        {openModalCreate && (
          <ModalTaskGroup
            type={"create"}
            open={openModalCreate}
            handleClose={() => setOpenModalCreate(false)}
            alertSuccess={() => {
              setSuccessAlert(true);
            }}
            alertError={(e) => {
              setErrorAlert(e);
            }}
          />
        )}
        {openModalEdit.open && (
          <ModalTaskGroup
            type={"edit"}
            open={openModalEdit.open}
            handleClose={() => setOpenModalEdit({ open: false, data: null })}
            data={openModalEdit.data}
            alertSuccess={() => {
              setSuccessAlert(true);
            }}
            alertError={(e) => {
              setErrorAlert(e);
            }}
          />
        )}
      </Container>
    </div>
  );
};

export default TableTaskGroup;
