import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Logo from "assets/image/WemoveLogo.svg";
import CloseDrawer from "assets/icons/closeDrawer.svg";
import {
  Person,
  HomeSharp,
  VerifiedUserSharp,
  PostAdd,
  DescriptionRounded,
  AdminPanelSettingsSharp,
  PinDropRounded,
  AccountBalanceRounded,
  AccountCircle,
  ExpandLess,
  ExpandMore,
  CurrencyExchangeOutlined,
  LocalShipping,
  AutoFixHigh,
  Assignment,
} from "@mui/icons-material";
import { FaTruckLoading, FaUserTie } from "react-icons/fa";
import { FaTruckFast } from "react-icons/fa6";
import {
  BsFillBoxSeamFill,
  BsDatabaseFillGear,
  BsFillFuelPumpFill,
  BsCurrencyExchange,
} from "react-icons/bs";
import { HiPresentationChartLine } from "react-icons/hi";
import { GiSellCard } from "react-icons/gi";
import { SiDocsdotrs } from "react-icons/si";
import { IoStorefrontSharp } from "react-icons/io5";
import { useTheme, Typography, Collapse } from "@mui/material";
import { AdminRole } from "utils/enum";
import { useCookies } from "react-cookie";
import API from "utils/api/auth/auth";
import { useNavigate, useLocation } from "react-router-dom";
const api = API.create();

export default function DrawerContent(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const [state, setState] = React.useState({
    left: false,
  });
  const [, , removeAccessToken] = useCookies(["access_token"]);
  const [openTransport, setOpenTransport] = React.useState(false);
  const [openTrade, setOpenTrade] = React.useState(false);
  const [openRental, setOpenRental] = React.useState(false);
  const [openAdvance, setOpenAdvance] = React.useState(false);
  const [isHoverTransport, setIsHoverTransport] = React.useState(false);
  const [isHoverTrade, setIsHoverTrade] = React.useState(false);
  const [isHoverRental, setIsHoverRental] = React.useState(false);
  const [isHoverAdvance, setIsHoverAdvance] = React.useState(false);
  const [isHoverHome, setIsHoverHome] = React.useState(false);
  const [isHoverTask, setIsHoverTask] = React.useState(false);

  const getColor = (path) => {
    return location.pathname === path
      ? theme.palette.primary.main
      : theme.palette.grey.main;
  };

  const [role, setRole] = React.useState("");
  React.useEffect(() => {
    console.log("props", props);
  }, [props]);
  React.useEffect(() => {
    if (props?.admin_info) {
      const admin_role = AdminRole?.find(
        (role) => props?.admin_info?.admin_role_type == role.value
      );
      console.log("admin_role", admin_role);
      setRole(admin_role?.name);
    }
  }, [props?.admin_info, AdminRole]);

  const handleTransport = () => {
    setOpenTransport(!openTransport);
  };
  const handleTrade = () => {
    setOpenTrade(!openTrade);
  };
  const handleRental = () => {
    setOpenRental(!openRental);
  };
  const handleAdvance = () => {
    setOpenAdvance(!openAdvance);
  };
  const handleLogout = async () => {
    await api
      .logout()
      .then((res) => {
        removeAccessToken("access_token");
        navigate("/login");
      })
      .catch((e) => console.log(e));
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 270 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem>
          <ListItemButton
            href="/"
            style={{}}
            sx={{
              width: "100% ",
              borderRadius: "5px",
              border: `2px solid ${isHoverHome ? "white" : "#121f43"}`,
            }}
            onMouseEnter={() => setIsHoverHome(true)}
            onMouseLeave={() => setIsHoverHome(false)}
          >
            <ListItemIcon size="medium" style={{ padding: "5px" }}>
              <HomeSharp
                fontSize="medium"
                sx={{
                  color: "primary.main",
                }}
              />
            </ListItemIcon>
            <ListItemText
              sx={{
                color: "primary.main",
              }}
            >
              <div style={{ fontSize: "14px", fontWeight: "500" }}>หน้าแรก</div>
            </ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton
            href="/task"
            style={{}}
            sx={{
              width: "100% ",
              border: `2px solid ${isHoverTask ? "white" : "#2499EF"}`,
              borderRadius: "5px",
            }}
            onMouseEnter={() => setIsHoverTask(true)}
            onMouseLeave={() => setIsHoverTask(false)}
          >
            <ListItemIcon size="medium" style={{ padding: "5px" }}>
              <Assignment
                fontSize="medium"
                sx={{
                  color: "#2499EF",
                }}
              />
            </ListItemIcon>
            <ListItemText
              sx={{
                color: "#2499EF",
              }}
            >
              <div style={{ fontSize: "14px", fontWeight: "500" }}>
                งานของฉัน
              </div>
            </ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem
          sx={{ display: "flex", flexDirection: "column" }}
          onMouseEnter={() => {
            setOpenTransport(true);
          }}
          onMouseLeave={() => {
            setOpenTransport(false);
          }}
        >
          <ListItemButton
            onClick={handleTransport}
            sx={{
              width: "100% ",
              border: `2px solid ${isHoverTransport ? "white" : "#8c8dff"}`,
              borderRadius: "5px",
            }}
            onMouseEnter={() => {
              setIsHoverTransport(true);
              setOpenTransport(true);
            }}
            onMouseLeave={() => {
              setIsHoverTransport(false);
            }}
          >
            <ListItemIcon size="medium" style={{ padding: "5px" }}>
              <FaTruckLoading fontSize="25px" style={{ color: "#8c8dff" }} />
            </ListItemIcon>
            <ListItemText sx={{ color: "#8c8dff" }}>
              <div style={{ fontSize: "14px", fontWeight: "500" }}>
                ระบบงานขนส่ง
              </div>
            </ListItemText>
            {openTransport ? (
              <ExpandLess style={{ color: "#8c8dff" }} />
            ) : (
              <ExpandMore style={{ color: "#8c8dff" }} />
            )}
          </ListItemButton>
          <Collapse
            in={openTransport}
            timeout="auto"
            unmountOnExit
            sx={{ width: "100%" }}
            onMouseLeave={() => {
              setOpenTransport(false);
            }}
          >
            <List component="div" disablePadding>
              <ListItem sx={{ pl: 3 }}>
                <ListItemButton href="/user">
                  <ListItemIcon size="medium" style={{ padding: "5px" }}>
                    <Person
                      fontSize="medium"
                      sx={{ color: getColor("/user") }}
                    />
                  </ListItemIcon>
                  <ListItemText sx={{ color: getColor("/user") }}>
                    <div style={{ fontSize: "14px" }}>ผู้ใช้งาน</div>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
              <ListItem sx={{ pl: 3 }}>
                <ListItemButton href="/truck">
                  <ListItemIcon size="medium" style={{ padding: "5px 8px" }}>
                    <FaTruckFast
                      fontSize="25px"
                      style={{ color: getColor("/truck") }}
                    />
                  </ListItemIcon>
                  <ListItemText sx={{ color: getColor("/truck") }}>
                    <div style={{ fontSize: "14px" }}>รถบรรทุก</div>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
              <ListItem sx={{ pl: 3 }}>
                <ListItemButton href="/work">
                  <ListItemIcon size="medium" style={{ padding: "5px 8px" }}>
                    <BsFillBoxSeamFill
                      fontSize="25px"
                      style={{ color: getColor("/work") }}
                    />
                  </ListItemIcon>
                  <ListItemText sx={{ color: getColor("/work") }}>
                    <div style={{ fontSize: "14px" }}>งานขนส่ง</div>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
              <ListItem sx={{ pl: 3 }}>
                <ListItemButton href="/finance/shipper/unpaid">
                  <ListItemIcon size="medium" style={{ padding: "5px" }}>
                    <AccountBalanceRounded
                      fontSize="medium"
                      sx={{ color: getColor("/finance/shipper/unpaid") }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    sx={{ color: getColor("/finance/shipper/unpaid") }}
                  >
                    <div style={{ fontSize: "14px" }}>การเงินขนส่ง</div>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            </List>
          </Collapse>
        </ListItem>
        <ListItem
          sx={{ display: "flex", flexDirection: "column" }}
          onMouseEnter={() => {
            setOpenTrade(true);
          }}
          onMouseLeave={() => {
            setOpenTrade(false);
          }}
        >
          <ListItemButton
            onClick={handleTrade}
            sx={{
              width: "100% ",
              border: `2px solid ${isHoverTrade ? "white" : "#ffc675"}`,
              borderRadius: "5px",
            }}
            onMouseEnter={() => {
              setIsHoverTrade(true);
              setOpenTrade(true);
            }}
            onMouseLeave={() => setIsHoverTrade(false)}
          >
            <ListItemIcon size="medium" style={{ padding: "5px 8px" }}>
              <GiSellCard fontSize="25px" style={{ color: "#ffc675" }} />
            </ListItemIcon>
            <ListItemText sx={{ color: "#ffc675" }}>
              <div style={{ fontSize: "14px", fontWeight: "500" }}>
                ระบบซื้อขาย
              </div>
            </ListItemText>
            {openTrade ? (
              <ExpandLess style={{ color: "#ffc675" }} />
            ) : (
              <ExpandMore style={{ color: "#ffc675" }} />
            )}
          </ListItemButton>
          <Collapse
            in={openTrade}
            timeout="auto"
            unmountOnExit
            sx={{ width: "100%" }}
            onMouseLeave={() => {
              setOpenTrade(false);
            }}
          >
            <List component="div" disablePadding>
              <ListItem sx={{ pl: 3 }}>
                <ListItemButton href="/trade/customer">
                  <ListItemIcon size="medium" style={{ padding: "5px 8px" }}>
                    <FaUserTie
                      fontSize="25px"
                      style={{ color: getColor("/trade/customer") }}
                    />
                  </ListItemIcon>
                  <ListItemText sx={{ color: getColor("/trade/customer") }}>
                    <div style={{ fontSize: "14px" }}>ผู้ซื้อ</div>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
              <ListItem sx={{ pl: 3 }}>
                <ListItemButton href="/trade/jobber">
                  <ListItemIcon size="medium" style={{ padding: "5px 8px" }}>
                    <IoStorefrontSharp
                      fontSize="25px"
                      style={{ color: getColor("/trade/jobber") }}
                    />
                  </ListItemIcon>
                  <ListItemText sx={{ color: getColor("/trade/jobber") }}>
                    <div style={{ fontSize: "14px" }}>ผู้ขาย</div>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
              <ListItem sx={{ pl: 3 }}>
                <ListItemButton href="/trade">
                  <ListItemIcon size="medium" style={{ padding: "5px 8px" }}>
                    <SiDocsdotrs
                      fontSize="25px"
                      style={{ color: getColor("/trade") }}
                    />
                  </ListItemIcon>
                  <ListItemText sx={{ color: getColor("/trade") }}>
                    <div style={{ fontSize: "14px" }}>ซื้อขาย</div>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
              <ListItem sx={{ pl: 3 }}>
                <ListItemButton href="/trade-finance">
                  <ListItemIcon size="medium" style={{ padding: "5px 8px" }}>
                    <BsCurrencyExchange
                      fontSize="25px"
                      style={{ color: getColor("/trade-finance") }}
                    />
                  </ListItemIcon>
                  <ListItemText sx={{ color: getColor("/trade-finance") }}>
                    <div style={{ fontSize: "14px" }}>การเงินซื้อขาย</div>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            </List>
          </Collapse>
        </ListItem>
        <ListItem
          sx={{ display: "flex", flexDirection: "column" }}
          onMouseEnter={() => {
            setOpenRental(true);
          }}
          onMouseLeave={() => {
            setOpenRental(false);
          }}
        >
          <ListItemButton
            onClick={handleRental}
            sx={{
              width: "100% ",
              border: `2px solid ${isHoverRental ? "white" : "#FF69B4"}`,
              borderRadius: "5px",
            }}
            onMouseEnter={() => {
              setIsHoverRental(true);
            }}
            onMouseLeave={() => setIsHoverRental(false)}
          >
            <ListItemIcon size="medium" style={{ padding: "5px 8px" }}>
              <LocalShipping fontSize="medium" style={{ color: "#FF69B4" }} />
            </ListItemIcon>
            <ListItemText sx={{ color: "#FF69B4" }}>
              <div style={{ fontSize: "14px", fontWeight: "500" }}>
                ระบบเช่ารถ
              </div>
            </ListItemText>
            {openRental ? (
              <ExpandLess sx={{ color: "#FF69B4" }} />
            ) : (
              <ExpandMore sx={{ color: "#FF69B4" }} />
            )}
          </ListItemButton>
          <Collapse
            in={openRental}
            timeout="auto"
            unmountOnExit
            sx={{ width: "100%" }}
          >
            <List component="div" disablePadding>
              <ListItem sx={{ pl: 3 }}>
                <ListItemButton href="/truck-rental/tenant">
                  <ListItemIcon size="medium" style={{ padding: "5px 8px" }}>
                    <FaUserTie
                      fontSize="25px"
                      style={{ color: getColor("/truck-rental/tenant") }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    sx={{ color: getColor("/truck-rental/tenant") }}
                  >
                    <div style={{ fontSize: "14px" }}>ผู้เช่า</div>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
              <ListItem sx={{ pl: 3 }}>
                <ListItemButton href="/truck-rental/lessor">
                  <ListItemIcon size="medium" style={{ padding: "5px 8px" }}>
                    <IoStorefrontSharp
                      fontSize="25px"
                      style={{ color: getColor("/truck-rental/lessor") }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    sx={{ color: getColor("/truck-rental/lessor") }}
                  >
                    <div style={{ fontSize: "14px" }}>ผู้ให้เช่า</div>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
              <ListItem sx={{ pl: 3 }}>
                <ListItemButton href="/truck-rental/rental-order">
                  <ListItemIcon size="medium" style={{ padding: "5px 8px" }}>
                    <SiDocsdotrs
                      fontSize="25px"
                      style={{ color: getColor("/truck-rental/rental-order") }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    sx={{ color: getColor("/truck-rental/rental-order") }}
                  >
                    <div style={{ fontSize: "14px" }}>เช่ารถ</div>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
              <ListItem sx={{ pl: 3 }}>
                <ListItemButton href="/truck-rental/finance/tenant/unpaid">
                  <ListItemIcon size="medium" style={{ padding: "5px 8px" }}>
                    <BsCurrencyExchange
                      fontSize="25px"
                      style={{
                        color: getColor("/truck-rental/finance/tenant/unpaid"),
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      color: getColor("/truck-rental/finance/tenant/unpaid"),
                    }}
                  >
                    <div style={{ fontSize: "14px" }}>การเงินเช่ารถ</div>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            </List>
          </Collapse>
        </ListItem>
        <ListItem
          sx={{ display: "flex", flexDirection: "column" }}
          onMouseEnter={() => {
            setOpenAdvance(true);
          }}
          onMouseLeave={() => {
            setOpenAdvance(false);
          }}
        >
          <ListItemButton
            onClick={handleAdvance}
            sx={{
              width: "100% ",
              border: `2px solid ${isHoverAdvance ? "white" : "#27ce88"}`,
              borderRadius: "5px",
            }}
            onMouseEnter={() => {
              setIsHoverAdvance(true);
            }}
            onMouseLeave={() => setIsHoverAdvance(false)}
          >
            <ListItemIcon size="medium" style={{ padding: "5px 8px" }}>
              <AutoFixHigh fontSize="medium" style={{ color: "#27ce88" }} />
            </ListItemIcon>
            <ListItemText sx={{ color: "#27ce88" }}>
              <div style={{ fontSize: "14px", fontWeight: "500" }}>ขั้นสูง</div>
            </ListItemText>
            {openAdvance ? (
              <ExpandLess sx={{ color: "#27ce88" }} />
            ) : (
              <ExpandMore sx={{ color: "#27ce88" }} />
            )}
          </ListItemButton>
          <Collapse
            in={openAdvance}
            timeout="auto"
            unmountOnExit
            sx={{ width: "100%" }}
          >
            <List component="div" disablePadding>
              <ListItem>
                <ListItemButton href="/dashboard/work">
                  <ListItemIcon size="medium" style={{ padding: "5px 8px" }}>
                    <HiPresentationChartLine
                      fontSize="25px"
                      style={{ color: getColor("/dashboard/work") }}
                    />
                  </ListItemIcon>
                  <ListItemText sx={{ color: getColor("/dashboard/work") }}>
                    <div style={{ fontSize: "14px" }}>แผนภูมิ</div>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton href="/export-data">
                  <ListItemIcon size="medium" style={{ padding: "5px 8px" }}>
                    <DescriptionRounded
                      fontSize="medium"
                      style={{ color: getColor("/export-data") }}
                    />
                  </ListItemIcon>
                  <ListItemText sx={{ color: getColor("/export-data") }}>
                    <div style={{ fontSize: "14px" }}>รายงาน</div>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton href="/admin">
                  <ListItemIcon size="medium" style={{ padding: "5px" }}>
                    <AdminPanelSettingsSharp
                      fontSize="medium"
                      sx={{ color: getColor("/admin") }}
                    />
                  </ListItemIcon>
                  <ListItemText sx={{ color: getColor("/admin") }}>
                    <div style={{ fontSize: "14px" }}>ผู้ดูแลระบบ</div>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton href="/master-data/term-and-condition">
                  <ListItemIcon size="medium" style={{ padding: "5px 8px" }}>
                    <BsDatabaseFillGear
                      fontSize="25px"
                      style={{
                        color: getColor("/master-data/term-and-condition"),
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    sx={{ color: getColor("/master-data/term-and-condition") }}
                  >
                    <div style={{ fontSize: "14px" }}>ฐานข้อมูล</div>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            </List>
          </Collapse>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <div>
      <React.Fragment>
        <Drawer
          anchor="left"
          open={props.openDrawer}
          onClose={() => {
            props.onClose();
          }}
          PaperProps={{
            onMouseLeave: () => {
              props.onClose();
            },
          }}
        >
          <div
            style={{
              display: "flex",
              padding: "20px 10px",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <img src={Logo} height={"37px"} alt="logo wemove platform" />
            <img
              src={CloseDrawer}
              height={"30px"}
              onClick={() => {
                props.onClose();
              }}
              alt=""
            />
          </div>
          <div
            style={{ display: "flex", flexDirection: "row", padding: "0 10px" }}
          >
            <div>
              {props?.admin_info?.image ? (
                <img
                  src={`http://${props?.admin_info?.image}`}
                  width={"75px"}
                  height={"75px"}
                  alt="image profile"
                  style={{
                    objectFit: "cover",
                    borderRadius: "10px",
                    display: "block",
                    margin: "auto",
                    verticalAlign: "middle",
                  }}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    width: "75px",
                    height: "75px",
                    backgroundColor: `rgba(140, 163, 186, 0.2)`,
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "10px",
                  }}
                >
                  <AccountCircle
                    sx={{
                      fontSize: 48,
                      color: "grey.main",
                    }}
                  />
                </div>
              )}
            </div>
            <div style={{ padding: "0 10px" }}>
              <Typography sx={{ fontWeight: "500" }}>
                {props?.admin_info?.firstname || ""}{" "}
                {props?.admin_info?.lastname || ""}
              </Typography>
              <Typography sx={{ fontWeight: "300" }}>
                {props?.admin_info?.username || ""}
              </Typography>
              <Typography sx={{ fontWeight: "400", color: "grey.main" }}>
                {role || ""}
              </Typography>
            </div>
          </div>
          {list("left")}
          <List
            sx={{ marginTop: "auto", display: "flex", alignItems: "flex-end" }}
          >
            <ListItem>
              <ListItemButton onClick={() => handleLogout()}>
                <ListItemText
                  sx={{
                    color: "grey.main",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <div style={{ fontSize: "14px" }}>ออกจากระบบ</div>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          </List>
        </Drawer>
      </React.Fragment>
    </div>
  );
}
