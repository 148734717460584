import instance from "../../axiosInstance";

const getAllCustomer = async (param) => {
  try {
    return instance.get(`/api/all-trade-customer`, {
      params: param,
    });
  } catch (error) {
    console.log("error", error);
  }
};

const createCustomer = async (body) => {
  try {
    return instance.post(`/api/create-trade-customer`, body, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
  } catch (error) {
    console.log("error", error);
  }
};

const getCustomerById = async (id_customer) => {
  try {
    return instance.get(`/api/trade-customer/${id_customer}`);
  } catch (error) {
    console.log("error", error);
  }
};

const editCustomer = async (id_customer, body) => {
  try {
    return instance.patch(`/api/edit-trade-customer/${id_customer}`, body, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
  } catch (error) {
    console.log("error", error);
  }
};

const getAllTradeProject = async (id_customer, param) => {
  try {
    return instance.get(`/api/all-trade-project/${id_customer}`, {
      params: param,
    });
  } catch (error) {
    console.log("error", error);
  }
};

const getProjectTradeById = async (id_project) => {
  try {
    return instance.get(`/api/trade-project/${id_project}`);
  } catch (error) {
    console.log("error", error);
  }
};

const createTradeProject = async (body) => {
  try {
    return instance.post(`/api/create-trade-project`, body);
  } catch (error) {
    console.log("error", error);
  }
};

const editTradeProject = async (id_project, body) => {
  try {
    return instance.patch(`/api/edit-trade-project/${id_project}`, body);
  } catch (error) {
    console.log("error", error);
  }
};
const getAllJobber = async (param) => {
  try {
    return instance.get(`/api/all-trade-jobber`, {
      params: param,
    });
  } catch (error) {
    console.log("error", error);
  }
};
const getJobberById = async (id_jobber) => {
  try {
    return instance.get(`/api/trade-jobber/${id_jobber}`);
  } catch (error) {
    console.log("error", error);
  }
};

const createJobber = async (body) => {
  try {
    return instance.post(`/api/create-trade-jobber`, body, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
  } catch (error) {
    console.log("error", error);
  }
};

const editJobber = async (id_jobber, body) => {
  try {
    return instance.patch(`/api/edit-trade-jobber/${id_jobber}`, body, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
  } catch (error) {
    console.log("error", error);
  }
};
const getAllOrder = async (param) => {
  try {
    return instance.get(`/api/all-trade-order`, {
      params: param,
    });
  } catch (error) {
    console.log("error", error);
  }
};

const createOrder = async (body) => {
  try {
    return instance.post(`/api/create-trade-order`, body, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
  } catch (error) {
    console.log("error", error);
  }
};

const editOrderTrade = async (id_order, body) => {
  try {
    return instance.patch(`/api/edit-trade-order/${id_order}`, body, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
  } catch (error) {
    console.log("error", error);
  }
};

const cancelOrderTrade = async (id_order) => {
  try {
    return instance.patch(`/api/cancel-trade-order/${id_order}`);
  } catch (error) {
    console.log("error", error);
  }
};

const createTransport = async (body) => {
  try {
    return instance.post(`/api/create-trade-transport`, body);
  } catch (error) {
    console.log("error", error);
  }
};

const editOrderTransport = async (id_transport, body) => {
  try {
    return instance.patch(`/api/edit-trade-transport/${id_transport}`, body);
  } catch (error) {
    console.log("error", error);
  }
};

const paidTransport = async (id_transport, data) => {
  try {
    return instance.patch(`/api/paid-trade-transport/${id_transport}`, data);
  } catch (error) {
    console.log("error", error);
  }
};

const cancelTradeTransport = async (id_trade_transport) => {
  try {
    return instance.patch(`/api/cancel-trade-transport/${id_trade_transport}`);
  } catch (error) {
    console.log("error", error);
  }
};
export {
  getAllCustomer,
  createCustomer,
  getCustomerById,
  editCustomer,
  getAllTradeProject,
  getProjectTradeById,
  createTradeProject,
  editTradeProject,
  getAllJobber,
  getJobberById,
  createJobber,
  editJobber,
  getAllOrder,
  createOrder,
  editOrderTrade,
  cancelOrderTrade,
  createTransport,
  editOrderTransport,
  paidTransport,
  cancelTradeTransport,
};
