import LoadingBackground from "components/loading/LoadingBackground";
import React, { useEffect, useState } from "react";
import styles from "./TableTask.module.css";
import { Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Controller, useForm } from "react-hook-form";
import {
  apiAssignTask,
  apiCreateTask,
  apiEditTask,
  apiGetAllTaskGroup,
} from "utils/api/task/task";
import FieldInput from "components/field/FieldInput";
import FieldSelect from "components/field/FieldSelect";
import FieldDatePicker from "components/field/FieldDatePicker";
import ButtonCustom from "components/ButtonCustom";
import { useCookies } from "react-cookie";
import { getAllAdmin as getAllAdminAPI } from "utils/api/admin/admin";
const ModalCreateTask = (props) => {
  const [, , removeAccessToken] = useCookies(["access_token"]);
  const [loading, setLoading] = useState(false);
  const [listTaskGroup, setListTaskGroup] = useState([]);
  const [listAdmin, setListAdmin] = useState([]);
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setValue,
    getValues,
    setError,
    clearErrors,
    trigger,
  } = useForm();

  useEffect(() => {
    getTaskGroup();
  }, []);

  useEffect(() => {
    if (props.type === "edit") {
      setValue("task_group_id", props?.data?.task_group_id);
      setValue("task_topic", props?.data?.task_topic);
      setValue("task_start_date", props?.data?.task_start_date);
      setValue("task_due_date", props?.data?.task_due_date);
      setValue("task_detail", props?.data?.task_detail);
      setValue("task_private", props?.data?.task_private);
      setValue("active", props?.data?.active);
      setValue("assign_to_id", props?.data?.assign_to_id);
    }
  }, [props.data]);

  useEffect(() => {
    if (props.type === "create") {
      setValue("task_group_id", listTaskGroup[0]?.value);
      setValue("task_start_date", new Date());
      setValue("task_due_date", new Date());
      setValue("task_private", false);
    }
  }, [listTaskGroup]);
  const getTaskGroup = async () => {
    await apiGetAllTaskGroup({ active: true })
      .then((res) => {
        if (res.data.code === 1000) {
          let results = res.data.data;
          setListTaskGroup(
            ...listTaskGroup,
            results?.map((res) => ({
              value: res.id,
              name: res.task_group_name,
            }))
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (props.assignable) {
      getAllAdmin();
    }
  }, [props.assignable]);
  const getAllAdmin = async () => {
    const filter = {
      active: true,
    };
    await getAllAdminAPI(filter)
      .then((res) => {
        if (res.data.code === 1000) {
          let results = res.data.data;
          setListAdmin(
            ...listAdmin,
            results?.map((res) => ({
              value: res.id,
              name: res.firstname + " " + res.lastname,
            }))
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const onSubmit = async (data) => {
    console.log("data", data);
    setLoading(true);
    if (props.assignable) {
      await apiAssignTask(data)
        .then((res) => {
          if (res.data.code === 1000) {
            props?.handleClose();
            setLoading(false);
            props.alertSuccess();
          }
        })
        .catch((error) => {
          if (error) {
            setLoading(false);
            if (error.response.data.code === 4106) {
              props.alertError({
                open: true,
                desc: error.response.data.description,
              });
            } else if (error.response.data.code === "4105") {
              removeAccessToken("access_token");
              window.location.reload();
            }
          }
        });
    } else {
      await apiCreateTask(data)
        .then((res) => {
          if (res.data.code === 1000) {
            props?.handleClose();
            setLoading(false);
            props.alertSuccess();
          }
        })
        .catch((error) => {
          if (error) {
            setLoading(false);
            if (error.response.data.code === 4106) {
              props.alertError({
                open: true,
                desc: error.response.data.description,
              });
            } else if (error.response.data.code === "4105") {
              removeAccessToken("access_token");
              window.location.reload();
            }
          }
        });
    }
  };

  const onEdit = async (data) => {
    console.log("data onEdit", data);
    setLoading(true);
    await apiEditTask(props?.data?.id, data)
      .then((res) => {
        if (res.data.code === 1000) {
          props?.handleClose();
          setLoading(false);
          props.alertSuccess();
        }
      })
      .catch((error) => {
        if (error) {
          setLoading(false);
          if (error.response.data.code === 4106) {
            props.alertError({
              open: true,
              desc: error.response.data.description,
            });
          } else if (error.response.data.code === "4105") {
            removeAccessToken("access_token");
            window.location.reload();
          }
        }
      });
  };
  return (
    <div>
      {loading && <LoadingBackground loading={loading} />}
      <Modal
        open={props?.open}
        onClose={(event, reason) =>
          reason !== "backdropClick" ? props?.handleClose : ""
        }
        sx={{ zIndex: 1000 }}
      >
        <div className={styles.modalStyle}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <CloseIcon
              sx={{ color: "grey.main" }}
              onClick={props?.handleClose}
            />
          </div>
          <div style={{ fontSize: 20, fontWeight: 400, textAlign: "center" }}>
            {props.type === "edit" ? "แก้ไขงาน" : "เพิ่มงาน"}
          </div>
          <form
            id="form-task-content"
            onSubmit={handleSubmit(props.type === "edit" ? onEdit : onSubmit)}
          >
            <Controller
              control={control}
              name="task_group_id"
              rules={{
                required:
                  props.type === "edit" ? false : "กรุณาเลือกชื่อกลุ่มงาน",
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <div style={{ paddingTop: "20px", width: "100%" }}>
                  <FieldSelect
                    name={"ชื่อกลุ่มงาน*"}
                    options={listTaskGroup}
                    value={value}
                    onChange={onChange}
                    style={{ width: "-webkit-fill-available" }}
                    error={!!errors.task_group_id}
                    errorText={
                      errors.task_group_id ? errors.task_group_id.message : ""
                    }
                  />
                </div>
              )}
            />
            {props.assignable && props.type === "create" && (
              <Controller
                control={control}
                name="assign_to_id"
                rules={{
                  required: "กรุณาเลือกคนที่ต้องการมอบหมายงาน",
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <div style={{ width: "100%" }}>
                    <FieldSelect
                      name={"มอบหมายให้*"}
                      options={listAdmin}
                      value={value}
                      onChange={onChange}
                      style={{ width: "-webkit-fill-available" }}
                      defaultValue={[]}
                      multiSelect={true}
                      error={!!errors.assign_to_id}
                      errorText={
                        errors.assign_to_id ? errors.assign_to_id.message : ""
                      }
                    />
                  </div>
                )}
              />
            )}
            {props.assignable && props.type === "edit" && (
              <Controller
                control={control}
                name="assign_to_id"
                rules={{
                  required: false,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <div style={{ width: "100%" }}>
                    <FieldSelect
                      name={"มอบหมายให้*"}
                      options={listAdmin}
                      value={value}
                      onChange={onChange}
                      style={{ width: "-webkit-fill-available" }}
                      error={!!errors.assign_to_id}
                      errorText={
                        errors.assign_to_id ? errors.assign_to_id.message : ""
                      }
                    />
                  </div>
                )}
              />
            )}
            <Controller
              control={control}
              name="task_topic"
              rules={{
                required: props.type === "edit" ? false : "กรุณากรอกหัวข้องาน",
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <div style={{ width: "100%" }}>
                  <FieldInput
                    label="หัวข้องาน*"
                    value={value}
                    style={{ width: "100%" }}
                    onChange={onChange}
                    error={!!errors.task_topic}
                    errorText={
                      errors.task_topic ? errors.task_topic.message : ""
                    }
                  />
                </div>
              )}
            />
            <Controller
              control={control}
              name="task_detail"
              rules={{
                required:
                  props.type === "edit" ? false : "กรุณากรอกรายละเอียดงาน",
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <div style={{ width: "100%" }}>
                  <FieldInput
                    label="รายละเอียดงาน*"
                    value={value}
                    style={{ width: "100%" }}
                    onChange={onChange}
                    error={!!errors.task_detail}
                    helperText={
                      errors.task_detail ? errors.task_detail.message : ""
                    }
                  />
                </div>
              )}
            />
            <Controller
              control={control}
              name="task_start_date"
              rules={{
                required:
                  props.type === "edit" ? false : "กรุณาเลือกวันที่สั่งงาน",
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <div style={{ width: "100%" }}>
                  <FieldDatePicker
                    label="วันที่สั่งงาน*"
                    value={value}
                    style={{ width: "100%" }}
                    onChange={onChange}
                    error={!!errors.task_start_date}
                    helperText={
                      errors.task_start_date
                        ? errors.task_start_date.message
                        : ""
                    }
                  />
                </div>
              )}
            />
            <Controller
              control={control}
              name="task_due_date"
              rules={{
                required:
                  props.type === "edit" ? false : "กรุณาเลือกวันที่กำหนดส่ง",
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <div style={{ width: "100%" }}>
                  <FieldDatePicker
                    label="วันที่กำหนดส่ง*"
                    value={value}
                    style={{ width: "100%" }}
                    onChange={onChange}
                    error={!!errors.task_due_date}
                    helperText={
                      errors.task_due_date ? errors.task_due_date.message : ""
                    }
                  />
                </div>
              )}
            />
            <Controller
              name="task_private"
              control={control}
              render={({ field: { onChange, value } }) => (
                <div style={{ width: "100%" }}>
                  <FieldSelect
                    name={"งานส่วนตัว"}
                    nameDescription={`ถ้าเป็นงานส่วนตัวที่ไม่ต้องรายงานผู้บริหารให้เลือก "ใช่"`}
                    options={[
                      { value: true, name: "ใช่" },
                      { value: false, name: "ไม่ใช่" },
                    ]}
                    value={value}
                    onChange={onChange}
                    style={{ width: "-webkit-fill-available" }}
                    error={!!errors.task_private}
                    errorText={
                      errors.task_private ? errors.task_private.message : ""
                    }
                  />
                </div>
              )}
            />
            {/* {props.type === "edit" && (
              <Controller
                name="active"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <div style={{ width: "100%" }}>
                    <FieldSelect
                      name={"สถานะใช้งาน"}
                      options={[
                        { value: true, name: "ใช้งาน" },
                        { value: false, name: "ไม่ใช้งาน" },
                      ]}
                      value={value}
                      onChange={onChange}
                      style={{ width: "-webkit-fill-available" }}
                      error={!!errors.active}
                      errorText={errors.active ? errors.active.message : ""}
                    />
                  </div>
                )}
              />
            )} */}
            <div style={{ paddingTop: "20px", display: "flex" }}>
              <div style={{ flex: 0.5 }}>
                <ButtonCustom
                  name={"ยกเลิก"}
                  onClick={props?.handleClose}
                  type={"cancel"}
                />
              </div>

              <div style={{ flex: 0.5 }}>
                <ButtonCustom name={"บันทึก"} type="submit" fullWidth={true} />
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default ModalCreateTask;
