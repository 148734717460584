import {
  Avatar,
  Badge,
  Box,
  Container,
  Drawer,
  Grid,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import Loading from "components/loading/Loading";
import TableCustom from "components/table/TableCustom";
import React, { useContext, useEffect, useState } from "react";
import styles from "./TableTask.module.css";
import FieldSelect from "components/field/FieldSelect";
import FieldDateRangePicker from "components/field/FieldDateRangePicker";
import {
  Add,
  Article,
  Download,
  Edit,
  FilterAlt,
  Search,
  Update,
} from "@mui/icons-material";
import moment from "moment";
import SelectRowValue from "components/button/SelectRowValue";
import SubMenu from "components/layout/SubMenu.js";
import {
  apiGetAllTask,
  apiGetAllTaskForAssignable,
  exportTask,
} from "utils/api/task/task";
import { useCookies } from "react-cookie";
import AuthContext from "components/context/AuthContext";
import CloseDrawer from "assets/icons/closeDrawer.svg";
import ButtonCustom from "components/ButtonCustom";
import helper from "utils/helper";
import TableActiveIcon from "components/tableActiveIcon";
import ModalCreateTask from "./ModalCreateTask";
import AlertModal from "components/modal/AlertModal";
import UpdateStatusTask from "./UpdateStatusTask";
import ModalTaskStatusLog from "./ModalTaskStatusLog";
import LightTooltip from "components/tooltip/LightTooltip";

export const AdminRole = [
  { value: 0, name: "super_admin" },
  { value: 1, name: "sale" },
  { value: 2, name: "customer_service" },
  { value: 3, name: "operation" },
  { value: 4, name: "finance_and_accounting" },
  { value: 5, name: "it" },
];

export const TaskStatus = [
  { value: 1, name: "ยังไม่เริ่มดำเนินการ" },
  { value: 2, name: "รับทราบงาน" },
  { value: 3, name: "กําลังดําเนินการ" },
  { value: 4, name: "เสร็จสิ้น" },
  { value: 5, name: "ยกเลิกงาน" },
];

const TableTaskAll = () => {
  const { adminAuth } = useContext(AuthContext);
  const [, , removeAccessToken] = useCookies(["access_token"]);
  const [loading, setLoading] = useState(false);
  const [dateTo, setDateTo] = useState(moment().format("YYYY-MM-DD 23:59:59"));
  const [dateFrom, setDateFrom] = useState(
    moment().subtract(30, "days").format("YYYY-MM-DD 00:00:00")
  );
  const [filterTask, setFilterTask] = useState({
    sort_by: "task_due_date",
    start_date: "",
    end_date: "",
    page: 1,
    per_page: 5,
    total: 0,
    task_status: [1],
    task_overdue: [],
    task_private: [],
  });
  const [advanceFilter, setAdvanceFilter] = useState(false);
  const [allTask, setAllTask] = useState([]);
  const [errorAlert, setErrorAlert] = useState({ open: false, desc: "" });
  const [successAlert, setSuccessAlert] = useState(false);
  const [openModalCreateTask, setOpenModalCreateTask] = useState(false);
  const [openTaskLogs, setOpenTaskLogs] = useState({ open: false, data: null });
  const [openModalUpdateTask, setOpenModalUpdateTask] = useState({
    open: false,
    data: null,
  });
  const [openModalEditTask, setOpenModalEditTask] = useState({
    open: false,
    data: null,
  });
  const [hasPermission, setHasPermission] = useState(false);
  const [tabValue, setTabValue] = useState(1);
  const [unstarted, setUnstarted] = useState(0);
  const [accepted, setAccepted] = useState(0);
  const [inProgress, setInProgress] = useState(0);
  const [completed, setCompleted] = useState(0);
  const [canceled, setCanceled] = useState(0);
  const [overdue, setOverdue] = useState(0);
  useEffect(() => {
    const hasP = adminAuth?.adminPermissions?.some((item) => {
      return item?.menu_permission_id === 24 && item?.visit === true;
    });
    setHasPermission(hasP);
  }, [adminAuth]);

  const subMenu = [
    {
      label: "จัดการงานตัวเอง",
      path: "/task",
    },
    {
      label: "จัดการกลุ่มงาน",
      path: "/task/task-group",
    },
    hasPermission && {
      label: "จัดการงานทั้งระบบ",
      path: "/task/all-task",
    },
  ];
  const breadcrumbs = [
    {
      label: "จัดการงานทั้งระบบ",
      path: null,
    },
  ];

  const columns = [
    {
      id: "task_start_date",
      label: "วันที่สั่งงาน",
      align: "center",
      //   width: "13%",
      format: (value) =>
        helper.ToConvertDateTimeForTable(value.task_start_date, false),
    },
    {
      id: "task_due_date",
      label: "วันที่กำหนดส่ง",
      align: "center",
      //   width: "15%",
      format: (value) => (
        <div>
          <div
            style={{
              color: value.task_overdue === true ? "#FF316F" : "#121f43",
            }}
          >
            {helper.ToConvertDateTimeForTable(value.task_due_date, false)}
          </div>
          {value.task_overdue === true && (
            <div style={{ color: "#FF316F" }}>เกินกําหนด</div>
          )}
        </div>
      ),
    },
    {
      id: "task_private",
      label: "งานส่วนตัว",
      align: "center",
      format: (value) => (value?.task_private ? "ใช่" : "ไม่ใช่"),
    },
    {
      id: "task_group_name",
      label: "กลุ่มงาน",
      align: "center",
      format: (value) => value?.task_group_details?.task_group_name,
    },
    {
      id: "task_topic",
      label: "หัวข้องาน",
      align: "center",
    },
    {
      id: "task_detail",
      label: "รายละเอียดงาน",
      align: "center",
      format: (value) => (
        <LightTooltip title={value.task_detail}>
          <div className={styles.ellipsisNameTaskDetail}>
            {value.task_detail}
          </div>
        </LightTooltip>
      ),
    },
    {
      id: "task_status",
      label: "สถานะงาน",
      align: "center",
      format: (value) => (
        <div>
          <div>
            {TaskStatus.find((item) => item.value === value.task_status)?.name}
          </div>
          <div>
            {value.task_status_log[0]?.actual_date &&
              helper.ToConvertDateTimeForTable(
                value.task_status_log[0]?.actual_date,
                false
              )}
          </div>
        </div>
      ),
    },
    // {
    //   id: "role_admin",
    //   label: "ชื่อพนักงาน",
    //   align: "center",
    //   format: (value) =>
    //     value.admin_details.firstname + " " + value.admin_details.lastname,
    // },
    {
      id: "assign_to",
      label: "มอบหมายให้",
      align: "center",
      format: (value) => (
        <div>
          {value?.assign_to &&
            value?.assign_to?.firstname + " " + value?.assign_to?.lastname}
        </div>
      ),
    },
    {
      id: "action",
      label: "ทำรายการ",
      align: "center",
      format: (val) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ display: "flex", gap: "5px", paddingBottom: "5px" }}>
              <Avatar
                variant="rounded"
                sx={{
                  width: 25,
                  height: 25,
                  boxShadow: `0px 2px 2px 0px rgba(0, 0, 0, 0.15)`,
                  bgcolor: "white",
                }}
                onClick={() =>
                  adminAuth?.id === val?.admin_id
                    ? setOpenModalEditTask({ open: true, data: val })
                    : null
                }
              >
                <Edit
                  sx={{
                    color:
                      adminAuth?.id === val?.admin_id
                        ? "secondary.main"
                        : "gray.main",
                    fontSize: 16,
                  }}
                />
              </Avatar>
              <Avatar
                variant="rounded"
                sx={{
                  width: 25,
                  height: 25,
                  boxShadow: `0px 2px 2px 0px rgba(0, 0, 0, 0.15)`,
                  bgcolor: "white",
                }}
                onClick={() =>
                  val?.active
                    ? setOpenModalUpdateTask({ open: true, data: val })
                    : null
                }
              >
                <Update
                  sx={{
                    color: val?.active ? "secondary.main" : "gray.main",
                    fontSize: 16,
                  }}
                />
              </Avatar>
              <Avatar
                variant="rounded"
                sx={{
                  width: 25,
                  height: 25,
                  boxShadow: `0px 2px 2px 0px rgba(0, 0, 0, 0.15)`,
                  bgcolor: "white",
                }}
                onClick={() =>
                  val?.active
                    ? setOpenTaskLogs({
                        open: true,
                        data: val?.task_status_log,
                      })
                    : null
                }
              >
                <Article
                  sx={{
                    color: val?.active ? "secondary.main" : "gray.main",
                    fontSize: 16,
                  }}
                />
              </Avatar>
            </div>
            <div>
              {val.admin_details.firstname} {val.admin_details.lastname}
            </div>
          </div>
        );
      },
    },
  ];

  const filterBar = () => {
    return (
      <div
        style={{
          borderRadius: "10px",
          backgroundColor: "white",
          padding: "25px",
          boxShadow: `0px 4px 4px 0px rgba(140, 163, 186, 0.20)`,
          zIndex: 2,
          flexGrow: 1,
        }}
      >
        <Grid container rowSpacing={2} justifyContent="center">
          <Grid className={styles.gridItem} item xs={12} sm={6} md={6} lg={2.5}>
            <FieldSelect
              name={"กรองและจัดเรียงตาม"}
              options={[
                { value: "created_at", name: "วันที่สร้างรายการ" },
                { value: "updated_at", name: "วันที่แก้ไขข้อมูลล่าสุด" },
                { value: "task_due_date", name: "วันที่กำหนดส่ง" },
              ]}
              style={{
                width: "-webkit-fill-available",
              }}
              defaultValue={"updated_at"}
              value={filterTask.sort_by}
              onChange={(val) => setFilterTask({ ...filterTask, sort_by: val })}
              closePaddingBottom={true}
            />
          </Grid>
          <Grid className={styles.gridItem} item xs={12} sm={6} md={6} lg={2.5}>
            <FieldDateRangePicker
              className="dateSelect"
              name={`วันเริ่มต้น - วันสิ้นสุด`}
              value={{
                start_date: filterTask?.start_date ?? dateFrom,
                end_date: filterTask?.end_date ?? dateTo,
              }}
              style={{ width: "-webkit-fill-available" }}
              onChange={(val) =>
                setFilterTask({
                  ...filterTask,
                  start_date: val[0],
                  end_date: val[1],
                })
              }
            />
          </Grid>
          <Grid className={styles.gridItem} item xs={12} sm={6} md={6} lg={2.5}>
            <FieldSelect
              name={"ค้นหาจาก"}
              options={[{ value: "task_topic", name: "หัวข้องาน" }]}
              style={{
                width: "-webkit-fill-available",
              }}
              defaultValue={"task_topic"}
              value={filterTask.search_by}
              onChange={(val) =>
                setFilterTask({ ...filterTask, search_by: val })
              }
              closePaddingBottom={true}
            />
          </Grid>
          <Grid className={styles.gridItem} item xs={12} sm={6} md={6} lg={2.5}>
            <TextField
              id="search_val"
              variant="standard"
              style={{
                paddingTop: "16px",
              }}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              value={filterTask.search_val ? filterTask.search_val : ""}
              onChange={(val) =>
                setFilterTask({ ...filterTask, search_val: val.target.value })
              }
            />
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              alignItems: "center",
            }}
            justifyContent="center"
            xs={12}
            sm={6}
            md={6}
            lg={2}
          >
            <Avatar
              variant="rounded"
              sx={{
                width: "40px",
                height: "40px",
                filter: "drop-shadow(0px 4px 4px rgba(140, 163, 186, 0.2))",
                bgcolor: "white",
              }}
              onClick={() => {
                const filter = { ...filterTask, page: 1 };
                getAllTask(filter);
              }}
            >
              <Search sx={{ color: "secondary.main", fontSize: 35 }} />
            </Avatar>
            <SelectRowValue
              onSelect={(val) => {
                setFilterTask({ ...filterTask, per_page: val });
              }}
            />
            <Avatar
              variant="rounded"
              sx={{
                width: "40px",
                height: "40px",
                filter: "drop-shadow(0px 4px 4px rgba(140, 163, 186, 0.2))",
                bgcolor: "white",
              }}
              onClick={exportExcel}
            >
              <Download sx={{ color: "secondary.main", fontSize: 35 }} />
            </Avatar>
            <Avatar
              variant="rounded"
              sx={{
                width: "40px",
                height: "40px",
                filter: "drop-shadow(0px 4px 4px rgba(140, 163, 186, 0.2))",
                bgcolor: "white",
              }}
              onClick={() => setAdvanceFilter(true)}
            >
              <FilterAlt sx={{ color: "secondary.main", fontSize: 35 }} />
            </Avatar>
          </Grid>
        </Grid>
      </div>
    );
  };

  useEffect(() => {
    getAllTask(filterTask);
  }, [
    filterTask.page,
    filterTask.per_page,
    filterTask.task_overdue,
    filterTask.task_status,
    filterTask.task_overdue,
  ]);

  const exportExcel = async () => {
    try {
      setLoading(true);
      const param = {
        start_date: filterTask?.start_date ?? "",
        end_date: filterTask?.end_date ?? "",
      };
      const res = await exportTask(param);
      if (res) {
        helper.downloadFileFromResponse(res);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const getAllTask = async (_filter) => {
    setLoading(true);
    await apiGetAllTaskForAssignable(_filter)
      .then((res) => {
        setLoading(false);
        if (res.data.code === 1000) {
          let dataAT = res.data.data;
          console.log("dataAT", dataAT);
          const reverse = dataAT?.map((item) => ({
            ...item,
            task_status_log: item?.task_status_log?.sort((a, b) => b.id - a.id),
          }));
          setAllTask(reverse);
          setUnstarted(res.data.unstarted);
          setAccepted(res.data.accepted);
          setInProgress(res.data.inprogress);
          setCompleted(res.data.completed);
          setCanceled(res.data.canceled);
          setOverdue(res.data.overdue);
          setFilterTask({ ...filterTask, total: res.data.total });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        if (error.response.data.code === 4106) {
          setErrorAlert({
            open: true,
            desc: error.response.data.description,
          });
        } else if (error.response.data.code === "4105") {
          removeAccessToken("access_token");
          window.location.reload();
        }
      });
  };

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
    console.log("newValue", newValue);
    if (newValue === "overdue") {
      setFilterTask({
        ...filterTask,
        page: 1,
        task_status: [],
        task_overdue: [true],
      });
    } else {
      setFilterTask({
        ...filterTask,
        page: 1,
        task_status: [newValue],
        task_overdue: [],
      });
    }
  };

  return (
    <div>
      {loading && <Loading />}
      <AlertModal
        open={successAlert}
        title="บันทึก"
        description="บันทึกข้อมูลสำเร็จ"
        onClose={() => {
          setSuccessAlert(false);
          const filter = { ...filterTask, page: 1 };
          getAllTask(filter);
        }}
      />
      <AlertModal
        open={errorAlert.open}
        title="เกิดข้อผิดพลาด"
        description={errorAlert.desc}
        onClose={() => {
          setErrorAlert({
            open: false,
            desc: "",
          });
        }}
      />
      <Drawer
        anchor="right"
        open={advanceFilter}
        onClose={() => {
          setAdvanceFilter(false);
          const filter = { ...filterTask, page: 1 };
          getAllTask(filter);
        }}
        sx={{
          width: "424px",
          zIndex: 100,
        }}
      >
        <div
          style={{
            width: "424px",
            display: "flex",
            padding: "34px",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <img
            src={CloseDrawer}
            height={"30px"}
            onClick={() => setAdvanceFilter(false)}
            alt=""
            style={{ transform: "scaleX(-1)" }}
          />
          <div style={{ padding: "10px", width: "100%" }}>
            <Typography
              fontSize={"20px"}
              fontWeight={500}
              sx={{ padding: "26px 0" }}
            >
              การกรองและการจัดเรียง
            </Typography>
            {/* <FieldSelect
              name={"กรองและการจัดเรียงตาม"}
              options={[
                { value: "created_at", name: "วันที่สร้างรายการ" },
                { value: "updated_at", name: "วันที่แก้ไขข้อมูลล่าสุด" },
                { value: "task_due_date", name: "วันที่กำหนดส่ง" },
              ]}
              style={{
                width: "-webkit-fill-available",
              }}
              value={filterTask.sort_by}
              onChange={(val) => setFilterTask({ ...filterTask, sort_by: val })}
              closePaddingBottom={true}
            />
            <FieldDateRangePicker
              className="dateSelect"
              name={`วันเริ่มต้น - วันสิ้นสุด`}
              value={{
                start_date: filterTask?.start_date ?? dateFrom,
                end_date: filterTask?.end_date ?? dateTo,
              }}
              style={{ width: "-webkit-fill-available" }}
              onChange={(val) =>
                setFilterTask({
                  ...filterTask,
                  start_date: val[0],
                  end_date: val[1],
                })
              }
            />
            <FieldSelect
              name="สถานะงาน"
              options={[
                { value: 1, name: "ยังไม่เริ่มดำเนินการ" },
                { value: 2, name: "รับทราบงาน" },
                { value: 3, name: "กําลังดําเนินการ" },
                { value: 4, name: "เสร็จสิ้น" },
                { value: 5, name: "ยกเลิกงาน" },
              ]}
              value={filterTask.task_status}
              defaultValue={[]}
              onChange={(val) => {
                setFilterTask({
                  ...filterTask,
                  task_status: val,
                });
              }}
              style={{
                width: "-webkit-fill-available",
              }}
              multiSelect={true}
              showSelectAll={true}
              closePaddingBottom={true}
            /> */}
            <FieldSelect
              name="งานส่วนตัว"
              options={[
                { value: true, name: "ใช่" },
                { value: false, name: "ไม่ใช่" },
              ]}
              value={filterTask.task_private}
              defaultValue={[]}
              onChange={(val) => {
                setFilterTask({
                  ...filterTask,
                  task_private: val,
                });
              }}
              style={{
                width: "-webkit-fill-available",
              }}
              multiSelect={true}
              showSelectAll={true}
              closePaddingBottom={true}
            />
            <div style={{ width: "100%", paddingTop: "26px" }}>
              <ButtonCustom
                name={"ล้างการกรอง"}
                onClick={() => {
                  setFilterTask({
                    sort_by: "task_due_date",
                    start_date: "",
                    end_date: "",
                    page: 1,
                    per_page: 5,
                    total: 0,
                    task_status: [],
                    task_overdue: [],
                    task_private: [],
                  });
                }}
                fullWidth={true}
              />
            </div>
          </div>
        </div>
      </Drawer>
      <Container>
        <SubMenu
          menus={subMenu.map((menu) => {
            return {
              label: menu?.label,
              path: menu?.path,
              active: menu?.label === `จัดการงานทั้งระบบ` ? true : false,
            };
          })}
          breadcrumbs={breadcrumbs}
          pathbackward={"/"}
        />
        <div style={{ paddingTop: "20px" }}></div>
        {filterBar()}
        <div>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={tabValue}
              onChange={handleChangeTab}
              aria-label="basic tabs example"
            >
              <Tab
                label={
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <div>ยังไม่เริ่มดำเนินการ</div>
                    <Badge
                      badgeContent={unstarted}
                      color="purple_theme"
                      sx={{ ml: 2 }}
                    />
                  </Box>
                }
                value={1}
              />
              <Tab
                label={
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <div>รับทราบงาน</div>
                    <Badge
                      badgeContent={accepted}
                      color="info"
                      sx={{ ml: 2 }}
                    />
                  </Box>
                }
                value={2}
              />
              <Tab
                label={
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <div>กําลังดําเนินการ</div>
                    <Badge
                      badgeContent={inProgress}
                      color="warning"
                      sx={{ ml: 2 }}
                    />
                  </Box>
                }
                value={3}
              />
              <Tab
                label={
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <div>เสร็จสิ้น</div>
                    <Badge
                      badgeContent={completed}
                      color="success"
                      sx={{ ml: 2 }}
                    />
                  </Box>
                }
                value={4}
              />
              <Tab
                label={
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <div>ยกเลิกงาน</div>
                    <Badge
                      badgeContent={canceled}
                      color="grey_badge"
                      sx={{ ml: 2 }}
                    />
                  </Box>
                }
                value={5}
              />
              <Tab
                label={
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <div>เกินกำหนด</div>
                    <Badge
                      badgeContent={overdue}
                      color="error"
                      sx={{ ml: 2 }}
                    />
                  </Box>
                }
                value={"overdue"}
              />
            </Tabs>
          </Box>
        </div>
        <div>
          <TableCustom
            columns={columns}
            dataSource={allTask}
            pagination={filterTask}
            setPagination={(val) => {
              setFilterTask({
                ...filterTask,
                ...val,
              });
            }}
            // onSelectedRow={(val) => console.log(val)}
            // href={`/user/`}
            // checkBox={true}
            hrefDisabled
          />
        </div>

        <Avatar
          sx={{
            position: "fixed",
            bottom: "35px",
            right: "97px",
            width: 100,
            height: 100,
            bgcolor: "secondary.main",
          }}
          onClick={() => setOpenModalCreateTask(true)}
        >
          <Add sx={{ color: "white", fontSize: "90px" }} />
        </Avatar>

        {openModalCreateTask && (
          <ModalCreateTask
            type={"create"}
            open={openModalCreateTask}
            handleClose={() => setOpenModalCreateTask(false)}
            alertSuccess={() => {
              setSuccessAlert(true);
            }}
            alertError={(e) => {
              setErrorAlert(e);
            }}
            assignable
          />
        )}
        {openModalEditTask.open && (
          <ModalCreateTask
            type={"edit"}
            open={openModalEditTask.open}
            handleClose={() =>
              setOpenModalEditTask({ open: false, data: null })
            }
            data={openModalEditTask.data}
            alertSuccess={() => {
              setSuccessAlert(true);
            }}
            alertError={(e) => {
              setErrorAlert(e);
            }}
            assignable
          />
        )}
        {openModalUpdateTask.open && (
          <UpdateStatusTask
            open={openModalUpdateTask.open}
            handleClose={() =>
              setOpenModalUpdateTask({ open: false, data: null })
            }
            data={openModalUpdateTask.data}
            alertSuccess={() => {
              setSuccessAlert(true);
            }}
            alertError={(e) => {
              setErrorAlert(e);
            }}
          />
        )}
        {openTaskLogs.open && (
          <ModalTaskStatusLog
            open={openTaskLogs.open}
            handleClose={() => setOpenTaskLogs({ open: false, data: null })}
            data={openTaskLogs.data}
          />
        )}
      </Container>
    </div>
  );
};

export default TableTaskAll;
