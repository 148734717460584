import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import Loading from "components/loading/LoadingBackground";
import SubMenu from "components/layout/SubMenu.js";
import { Container, Avatar } from "@mui/material";
import { getUserById } from "utils/api/user/user";
import AlertModal from "components/modal/AlertModal";
import styles from "./TableSubUserInProfile.module.css";
import { Typography } from "@mui/material";
import helper from "utils/helper";
import FieldSelect from "components/field/FieldSelect";
import FieldInput from "components/field/FieldInput";
import { ActiveStatus } from "utils/enum";
import { useForm, Controller } from "react-hook-form";
import { Save } from "@mui/icons-material";
import { editUser } from "utils/api/user/user";
import ButtonCustom from "components/ButtonCustom";

const DetailSubUserInProfile = () => {
  const { user_id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [dataDetail, setDataDetail] = useState({});
  const [, , removeAccessToken] = useCookies(["access_token"]);
  const [errorAlert, setErrorAlert] = useState({ open: false, desc: "" });
  const [alertModal, setAlertModal] = useState({
    open: false,
    title: "",
    desc: "",
  });
  const subUserID = [
    {
      label: "รายละเอียดผู้ใช้รองในโปรไฟล์",
      active: true,
    },
  ];

  const breadcrumbs = [
    {
      label: "ข้อมูลผู้ใช้งาน",
      path: "/user",
    },
    {
      label: `${dataDetail?.user_profile?.profile?.user_info.user_code}`,
      path: "",
    },
    {
      label: "ข้อมูลผู้ใช้รองในโปรไฟล์",
      path: "",
    },
    {
      label: `${dataDetail?.user_code}`,
      path: "",
    },
    {
      label: "รายละเอียดผู้ใช้รองในโปรไฟล์",
      path: "",
    },
  ];

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setValue,
    getValues,
    setError,
    clearErrors,
    trigger,
  } = useForm();

  useEffect(() => {
    console.log("dataDetail", dataDetail);
    setValue("active", String(dataDetail.active) || "");
  }, [dataDetail]);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      getUserById(user_id)
        .then((response) => {
          if (response.data.code === 1000) {
            const rest = response.data.data;
            const roles = [];

            if (rest.user_profile.user_profile_shipper.is_shipper === true) {
              roles.push("shipper");
            }

            if (rest.user_profile.user_profile_carrier.is_carrier === true) {
              roles.push("carrier");
            }

            if (rest.user_profile.user_profile_driver.is_driver === true) {
              roles.push("driver");
            }

            rest["roles"] = roles;
            setDataDetail(rest);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.data.code === 4106) {
            setErrorAlert({
              open: true,
              desc: error.response.data.description,
            });
          } else if (error.response.data.code === "4105") {
            removeAccessToken("access_token");
            window.location.reload();
          }
        });
    }, 2000);
  }, [user_id]);

  const onSubmit = async (data) => {
    setLoading(true);

    const GForm = new FormData();
    if (data.active !== dataDetail.active) {
      GForm.append("active", data.active);
    }

    if (user_id) {
      await editUser(user_id, GForm)
        .then((response) => {
          console.log("response", response);
          if (response.data.code === 1000) {
            setLoading(false);
            setAlertModal({
              open: true,
              title: "บันทึก",
              desc: "บันทึกข้อมูลสำเร็จ",
            });
            console.log("edit Success");
          }
        })
        .catch(async (error) => {
          console.log("error", error);
          if (error) {
            setLoading(false);
            if (error.response.data.code === 4106) {
              setErrorAlert({
                open: true,
                desc: error.response.data.description,
              });
            } else if (error.response.data.code === "4105") {
              removeAccessToken("access_token");
              window.location.reload();
            }
          }
        });
    }
  };

  return (
    <React.Fragment>
      {loading && <Loading />}
      {alertModal.open === true && (
        <AlertModal
          open={alertModal.open}
          title={alertModal.title}
          description={alertModal.desc}
          onClose={() => {
            setAlertModal({
              open: false,
              title: "",
              desc: "",
            });
          }}
        />
      )}
      {errorAlert.open === true && (
        <AlertModal
          open={errorAlert.open}
          title="เกิดข้อผิดพลาด"
          description={errorAlert.desc}
          onClose={() => {
            setErrorAlert({
              open: false,
              desc: "",
            });
            navigate(-1);
          }}
        />
      )}
      <Container>
        <SubMenu
          menus={subUserID}
          breadcrumbs={breadcrumbs}
          pathbackward={-1}
        />
      </Container>
      <form
        className={styles.containerDetailProfile}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className={styles.cardDetail}>
          <Typography fontSize={"20px"} fontWeight={500} sx={{ p: "13px" }}>
            {dataDetail?.user_code || ""}
          </Typography>
          <Typography
            fontSize={"14px"}
            fontWeight={300}
            fontStyle={"italic"}
            color={"grey.main"}
          >
            วันที่สร้างรายการ{" "}
            {dataDetail?.created_at
              ? helper.ToConvertDateTimeForTable(dataDetail?.created_at)
              : ""}
          </Typography>
          <Typography
            fontSize={"14px"}
            fontWeight={300}
            fontStyle={"italic"}
            color={"grey.main"}
          >
            วันที่แก้ไขข้อมูลล่าสุด{" "}
            {dataDetail?.updated_at
              ? helper.ToConvertDateTimeForTable(dataDetail?.updated_at)
              : ""}
          </Typography>
          <Controller
            name="active"
            control={control}
            render={({ field: { onChange, value } }) => (
              <div style={{ paddingTop: "20px", width: "100%" }}>
                <FieldSelect
                  name="สถานะใช้งาน"
                  nameDescription="(ค่าเริ่มต้น : ใช้งาน)"
                  options={ActiveStatus}
                  value={value}
                  onChange={onChange}
                  style={{ width: "-webkit-fill-available" }}
                />
              </div>
            )}
          />

          <div style={{ width: "100%" }}>
            <FieldInput
              label="ชื่อผู้ใช้งานหลักในโปรไฟล์"
              value={`${
                dataDetail?.user_profile?.profile?.user_info.firstname || ""
              } ${dataDetail?.user_profile?.profile?.user_info.lastname || ""}`}
              readOnly={true}
              style={{ width: "-webkit-fill-available" }}
            />
          </div>

          <div style={{ width: "100%" }}>
            <ButtonCustom
              name="ดูข้อมูลส่วนตัว"
              fullWidth={true}
              onClick={() => navigate(`/user/${user_id}`)}
            />
          </div>
          <Avatar
            alt="Avatar Save"
            sx={{
              position: "fixed",
              bottom: "35px",
              right: "97px",
              width: 100,
              height: 100,
              bgcolor: "secondary.main",
              cursor: "pointer",
              zIndex: 200,
            }}
            onClick={handleSubmit(onSubmit)}
          >
            <Save sx={{ color: "white", fontSize: "70px" }} />
          </Avatar>
        </div>
      </form>
    </React.Fragment>
  );
};

export default DetailSubUserInProfile;
