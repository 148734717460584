import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  FormControl,
  Grid,
  Table,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import FieldDateRangePicker from "components/field/FieldDateRangePicker";
import SubMenu from "components/layout/SubMenu.js";
import * as moment from "moment";
import { useEffect, useState } from "react";
import {
  getDailyIncome,
  getDueDateBuyerInvoice,
  getDueDateBuyerShipper,
  getDueDateInvoice,
  getDueDateShipper,
  getEWHTGinkgo,
  getLessorVatXlsx,
  getLessorWhtXlsx,
  getMonthlyIncome,
  getPpd3,
  getPpd53,
  getProfile,
  getProject,
  getReceiptGinkgo,
  getReceiptGinkgoTrade,
  getRentalOrderXlsx,
  getTenantDueDateXlsx,
  getTenantGinkgoXlsx,
  getTenantVatXlsx,
  getTradeOrder,
  getTradeTransport,
  getTruck,
  getUnpiadBuyerInvoice,
  getUnpiadBuyerShipper,
  getUnpiadInvoice,
  getUnpiadShipper,
  getUser,
  getWork,
  getWorkByMatching,
  getฺBuyerVat,
  getฺSellerVat,
  getฺTradeCarrierWHT,
} from "utils/api/export-data/export-data";
import { subMenuDasdboard } from "utils/submenuoptions";
import "./Dashboard.css";
import CloudDownload from "@mui/icons-material/CloudDownload";
import Loading from "components/loading/Loading";
import LoadingBackground from "components/loading/LoadingBackground";
import FieldSelect from "components/field/FieldSelect";
import helper from "utils/helper";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { none } from "ramda";

import User from "assets/report/User.svg";
import Profile from "assets/report/Profile.svg";
import Truck from "assets/report/Truck.svg";
import Project from "assets/report/Project.svg";
import Work1 from "assets/report/Work1.svg";
import Work2 from "assets/report/Work2.svg";
import Receipt_Transport from "assets/report/Receipt_Transport.svg";
import WHT from "assets/report/WHT.svg";
import Deptor_Transport1 from "assets/report/Deptor_Transport1.svg";
import Deptor_Transport2 from "assets/report/Deptor_Transport2.svg";
import Deptor_Transport3 from "assets/report/Deptor_Transport3.svg";
import Deptor_Transport4 from "assets/report/Deptor_Transport4.svg";
import Buyer from "assets/report/Buyer.svg";
import Seller from "assets/report/Seller.svg";
import Trade_PO from "assets/report/Trade_PO.svg";
import Trade_Transport from "assets/report/Trade_Transport.svg";
import Receipt_Trade from "assets/report/Receipt_Trade.svg";
import Deptor_Trade1 from "assets/report/Deptor_Trade1.svg";
import Deptor_Trade2 from "assets/report/Deptor_Trade2.svg";
import Deptor_Trade3 from "assets/report/Deptor_Trade3.svg";
import Deptor_Trade4 from "assets/report/Deptor_Trade4.svg";
import Sales_Tax from "assets/report/sales_tax.svg";
import Purchase_Tax from "assets/report/purchase_tax.svg";
import Trade_AR from "assets/report/trade_ar.svg";
import Trade_Carrier_WHT from "assets/report/trade_carrier_wht.svg";
import iconIncomedaily from "assets/report/incomedaily.svg";
import iconIncomemonthly from "assets/report/insomemonthly.svg";
import iconPpd3 from "assets/report/ppd3.svg";
import iconPpd53 from "assets/report/ppd53.svg";
import AlertModal from "components/modal/AlertModal";

const ExportData = () => {
  const [dateFrom, setDateFrom] = useState(
    moment().subtract(29, "days").format("YYYY-MM-DD 00:00:00")
  );
  const [dateTo, setDateTo] = useState(moment().format("YYYY-MM-DD 23:59:59"));
  const [isLoading, setIsLoading] = useState(false);
  const [subMenu_menus, setSubMenuMenus] = useState([...subMenuDasdboard]);
  const [subMenu_breadcrumbs, setSubMenuBreadcrumbs] = useState([]);
  const [errorAlert, setErrorAlert] = useState(false);
  useEffect(() => {
    setActiveSubMenu(5);
    setSubMenuBreadcrumbs([
      {
        label: "แผนภูมิและกราฟ",
        path: null,
      },
      {
        label: "รายงาน",
        path: null,
      },
    ]);
  }, []);

  const setActiveSubMenu = (index) => {
    const updatedMenus = subMenu_menus.map((menu, idx) => {
      return { ...menu, active: idx === index };
    });
    setSubMenuMenus(updatedMenus);
  };

  const handleOnChangeDate = (e, key) => {
    setDateFrom(e[0]);
    setDateTo(e[1]);
  };

  const handleOnSubmit = async (type) => {
    setIsLoading(true);
    const params = {
      dateFrom: moment(dateFrom).toISOString(),
      dateTo: moment(dateTo).toISOString(),
    };
    try {
      let res;
      if (type === "work") {
        res = await getWork(params);
      } else if (type === "project") {
        res = await getProject(params);
      } else if (type === "user") {
        res = await getUser(params);
      } else if (type === "profile") {
        res = await getProfile(params);
      } else if (type === "truck") {
        res = await getTruck(params);
      } else if (type === "ginkgo_t01") {
        res = await getReceiptGinkgo(params);
      } else if (type === "ginkgo_ewht") {
        res = await getEWHTGinkgo(params);
      } else if (type === "workmatching") {
        res = await getWorkByMatching(params);
      } else if (type === "tradeorder") {
        res = await getTradeOrder(params);
      } else if (type === "tradetrandsport") {
        res = await getTradeTransport(params);
      } else if (type === "due_date") {
        res = await getDueDateInvoice(params);
      } else if (type === "due_date_shipper") {
        res = await getDueDateShipper(params);
      } else if (type === "due_date_buyer_shipper") {
        res = await getDueDateBuyerShipper(params);
      } else if (type === "due_date_buyer_Invoice") {
        res = await getDueDateBuyerInvoice(params);
      } else if (type === "unpaid_shipper") {
        res = await getUnpiadShipper(params);
      } else if (type === "unpaid_invoice") {
        res = await getUnpiadInvoice(params);
      } else if (type === "unpaid_buyer_shipper") {
        res = await getUnpiadBuyerShipper(params);
      } else if (type === "unpaid_buyer_invoice") {
        res = await getUnpiadBuyerInvoice(params);
      } else if (type === "buyer_vat") {
        res = await getฺBuyerVat(params);
      } else if (type === "seller_vat") {
        res = await getฺSellerVat(params);
      } else if (type === "trade_carrier_wht") {
        res = await getฺTradeCarrierWHT(params);
      } else if (type === "ginkgo_t01_trade") {
        res = await getReceiptGinkgoTrade(params);
      } else if (type === "getDailyIncome") {
        res = await getDailyIncome(params);
      } else if (type === "getMonthlyIncome") {
        res = await getMonthlyIncome(params);
      } else if (type === "ppd3") {
        res = await getPpd3(params);
      } else if (type === "ppd53") {
        res = await getPpd53(params);
      } else if (type === "rental_order") {
        res = await getRentalOrderXlsx(params);
      } else if (type === "rental_tenant_ginkgo") {
        res = await getTenantGinkgoXlsx(params);
      } else if (type === "rental_tenant_due_data") {
        res = await getTenantDueDateXlsx(params);
      } else if (type === "rental_tenant_vat") {
        res = await getTenantVatXlsx(params);
      } else if (type === "rental_lessor_vat") {
        res = await getLessorVatXlsx(params);
      } else if (type === "rental_lessor_wht") {
        res = await getLessorWhtXlsx(params);
      }

      if (res) {
        helper.downloadFileFromResponse(res);
        setIsLoading(false);
      }
    } catch (error) {
      if (error.response.status === 403) {
        setErrorAlert(true);
        setIsLoading(false);
      }
    }
  };

  const menuWork = [
    {
      name: "ข้อมูลผู้ใช้งาน",
      submitType: "user",
      image: User,
    },
    { name: "ข้อมูลโปรไฟล์", submitType: "profile", image: Profile },
    { name: "ข้อมูลรถ", submitType: "truck", image: Truck },
    { name: "ข้อมูลโครงการ", submitType: "project", image: Project },
    {
      name: `ข้อมูลงานเรียงตามวันที่สร้างรายการ`,
      submitType: "work",
      image: Work1,
    },
    {
      name: "ข้อมูลงานเรียงตามวันที่จับคู่",
      submitType: "workmatching",
      image: Work2,
    },
    {
      name: "ข้อมูลใบเสร็จเรียงตามวันที่ชำระเงิน",
      submitType: "ginkgo_t01",
      image: Receipt_Transport,
    },
    {
      name: "ข้อมูลหนังสือรับรองการหัก ณ ที่จ่ายเรียงตามวันที่แจ้งโอน",
      submitType: "ginkgo_ewht",
      image: WHT,
    },
    {
      name: "รายการลูกหนี้แยกตามรายชื่อลูกหนี้",
      submitType: "due_date_shipper",
      image: Deptor_Transport1,
    },
    {
      name: "รายการลูกหนี้แยกตามใบแจ้งหนี้",
      submitType: "due_date",
      image: Deptor_Transport2,
    },
    {
      name: "งานรอแจ้งหนี้เรียงตามชื่อ",
      submitType: "unpaid_shipper",
      image: Deptor_Transport3,
    },
    {
      name: "งานรอแจ้งหนี้เรียงตามเลขที่งาน",
      submitType: "unpaid_invoice",
      image: Deptor_Transport4,
    },
    {
      name: "รายงานรับเงินประจำเดือน",
      submitType: "getDailyIncome",
      image: iconIncomedaily,
    },
    {
      name: "รายงานรับเงินประจำเดือน",
      submitType: "getMonthlyIncome",
      image: iconIncomemonthly,
    },
    {
      name: "ภงด.3",
      submitType: "ppd3",
      image: iconPpd3,
    },
    {
      name: "ภงด.53",
      submitType: "ppd53",
      image: iconPpd53,
    },
  ];

  const menuTrade = [
    {
      name: "ผู้ซื้อ",
      // submitType: "tradeorder",
      image: Buyer,
    },
    {
      name: "ผู้ขาย",
      // submitType: "tradeorder",
      image: Seller,
    },
    {
      name: "ข้อมูลซื้อขายแยกตามใบสั่งซื้อ",
      submitType: "tradeorder",
      image: Trade_PO,
    },
    {
      name: "ข้อมูลซื้อขายแยกตามการจัดส่ง",
      submitType: "tradetrandsport",
      image: Trade_Transport,
    },
    {
      name: "ใบเสร็จginkgo",
      submitType: "ginkgo_t01_trade",
      image: Receipt_Trade,
    },
    {
      name: "รายการยื่นภาษีสำหรับการซื้อ",
      submitType: "due_date_buyer_shipper",
      image: Trade_AR,
    },
    {
      name: "รายการยื่นภาษีสำหรับการซื้อ",
      submitType: "buyer_vat",
      image: Sales_Tax,
    },
    {
      name: "รายการยื่นภาษีสำหรับการขาย",
      submitType: "seller_vat",
      image: Purchase_Tax,
    },
    {
      name: "WHT (ค่าขนส่ง)",
      submitType: "trade_carrier_wht",
      image: Trade_Carrier_WHT,
    },
  ];

  const menuRental = [
    {
      name: "ผู้ซื้อ",
      // submitType: "tradeorder",
      image: Buyer,
    },
    {
      name: "ผู้ขาย",
      // submitType: "tradeorder",
      image: Seller,
    },
    {
      name: "ข้อมูลเช่ารถแยกตามใบสั่งซื้อ",
      submitType: "rental_order",
      image: Trade_PO,
    },
    {
      name: "ใบเสร็จginkgo",
      submitType: "rental_tenant_ginkgo",
      image: Receipt_Trade,
    },
    {
      name: "รายการยื่นภาษีสำหรับการเช่า",
      submitType: "rental_tenant_due_data",
      image: Trade_AR,
    },
    {
      name: "รายการยื่นภาษีสำหรับการเช่า",
      submitType: "rental_tenant_vat",
      image: Sales_Tax,
    },
    {
      name: "รายการยื่นภาษีสำหรับการให้เช่า",
      submitType: "rental_lessor_vat",
      image: Purchase_Tax,
    },
    {
      name: "WHT (ค่าขนส่ง)",
      submitType: "rental_lessor_wht",
      image: Trade_Carrier_WHT,
    },
  ];
  return (
    <>
      {isLoading && <LoadingBackground />}
      <AlertModal
        open={errorAlert}
        title="เกิดข้อผิดพลาด"
        description={"ไม่มีสิทธ์ในการเข้าถึง"}
        onClose={() => {
          setErrorAlert(false);
        }}
      />
      <div className="ExportData" style={{ paddingTop: "20px" }}>
        <Container>
          {/* <SubMenu menus={subMenu_menus} breadcrumbs={subMenu_breadcrumbs} /> */}
          <form className="form-updatestatus" autoComplete="off">
            <div
              style={{
                borderRadius: "10px",
                backgroundColor: "white",
                padding: "25px",
                boxShadow: `0px 4px 4px 0px rgba(140, 163, 186, 0.20)`,
                zIndex: 2,
                flexGrow: 1,
              }}
            >
              <Grid container spacing={2} justifyContent="center">
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  // xs={12}
                  // py={"20px"}
                  // px={"20px"}
                  // sx={{ backgroundColor: "#FFF", borderRadius: 3 }}
                >
                  <FormControl fullWidth>
                    <FieldSelect
                      className="w-100"
                      name={`กรองและจัดเรียงตาม`}
                      options={[
                        { value: "created_date", name: "วันที่สร้างรายการ" },
                      ]}
                      value={`created_date`}
                      style={{
                        width: "-webkit-fill-available",
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <FormControl fullWidth>
                    <FieldDateRangePicker
                      sx={{ mibWidth: "300px" }}
                      className="w-100"
                      name={`วันเริ่มต้น - วันสิ้นสุด`}
                      value={{
                        start_date: dateFrom,
                        end_date: dateTo,
                      }}
                      onChange={(e) => handleOnChangeDate(e, "date")}
                      style={{
                        width: "-webkit-fill-available",
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </div>
            <div style={{ marginTop: "20px" }}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ArrowDownwardIcon sx={{ color: "white" }} />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  sx={{ backgroundColor: "primary.main" }}
                >
                  <Typography sx={{ color: "white" }}>
                    ข้อมูลงานขนส่ง
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      padding: "20px",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      maxWidth: "70%",
                    }}
                  >
                    {menuWork?.map((ele, index) => (
                      <Grid item key={index} xs={12} sm={6} md={4} lg={2}>
                        <Box
                          sx={{
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "center",
                          }}
                          onClick={() =>
                            ele?.submitType
                              ? handleOnSubmit(ele?.submitType)
                              : null
                          }
                        >
                          <img
                            src={ele?.image}
                            alt={ele?.name}
                            draggable={false}
                            width={150}
                            height={150}
                          />
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </div>
            <div style={{ marginTop: "20px" }}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ArrowDownwardIcon sx={{ color: "white" }} />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  sx={{ backgroundColor: "primary.main" }}
                >
                  <Typography sx={{ color: "white" }}>
                    ข้อมูลงานซื้อขาย
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      padding: "20px",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      maxWidth: "70%",
                    }}
                  >
                    {menuTrade?.map((ele, index) => (
                      <Grid item key={index} xs={12} sm={6} md={4} lg={2}>
                        <Box
                          sx={{
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "center",
                          }}
                          onClick={() =>
                            ele?.submitType
                              ? handleOnSubmit(ele?.submitType)
                              : null
                          }
                        >
                          <img
                            src={ele?.image}
                            alt={ele?.name}
                            draggable={false}
                            width={150}
                            height={150}
                          />
                          {/* <div
                            key={index}
                            style={{ fontSize: "auto", textAlign: "center" }}
                            dangerouslySetInnerHTML={{ __html: ele?.name }}
                          /> */}
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </div>

            <div style={{ marginTop: "20px" }}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ArrowDownwardIcon sx={{ color: "white" }} />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  sx={{ backgroundColor: "primary.main" }}
                >
                  <Typography sx={{ color: "white" }}>
                    ข้อมูลงานเช่าซื้อ
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      padding: "20px",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      maxWidth: "70%",
                    }}
                  >
                    {menuRental?.map((ele, index) => (
                      <Grid item key={index} xs={12} sm={6} md={4} lg={2}>
                        <Box
                          sx={{
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "center",
                          }}
                          onClick={() =>
                            ele?.submitType
                              ? handleOnSubmit(ele?.submitType)
                              : null
                          }
                        >
                          <img
                            src={ele?.image}
                            alt={ele?.name}
                            draggable={false}
                            width={150}
                            height={150}
                          />
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </div>

            {/* <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Table>
                    <TableRow>
                      <TableCell className="text-center">
                        ข้อมูลผู้ใช้งาน
                      </TableCell>
                      <TableCell>
                        <div>
                          <CloudDownload
                            onClick={(e) => handleOnSubmit("user")}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="text-center">
                        ข้อมูลโปรไฟล์
                      </TableCell>
                      <TableCell>
                        <div>
                          <CloudDownload
                            onClick={(e) => handleOnSubmit("profile")}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="text-center">ข้อมูลรถ</TableCell>
                      <TableCell>
                        <div>
                          <CloudDownload
                            onClick={(e) => handleOnSubmit("truck")}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="text-center">
                        ข้อมูลโครงการ
                      </TableCell>
                      <TableCell>
                        <div>
                          <CloudDownload
                            onClick={(e) => handleOnSubmit("project")}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="text-center">ข้อมูลงาน</TableCell>
                      <TableCell>
                        <div>
                          <CloudDownload
                            onClick={(e) => handleOnSubmit("work")}
                          />
                          <span
                            style={{
                              marginLeft: "10px",
                            }}
                          >
                            เรียงตามวันที่สร้างรายการ
                          </span>
                          &nbsp;&nbsp;&nbsp;
                          <CloudDownload
                            onClick={(e) => handleOnSubmit("workmatching")}
                          />
                          <span
                            style={{
                              marginLeft: "10px",
                            }}
                          >
                            เรียงตามวันที่จับคู่
                          </span>
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="text-center">
                        ข้อมูลใบเสร็จ
                      </TableCell>
                      <TableCell>
                        <div>
                          <CloudDownload
                            onClick={(e) => handleOnSubmit("ginkgo_t01")}
                          />
                          <span
                            style={{
                              marginLeft: "10px",
                            }}
                          >
                            เรียงตามวันที่ชำระเงิน
                          </span>
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="text-center">
                        ข้อมูลหนังสือรับรองการหัก ณ ที่จ่าย
                      </TableCell>
                      <TableCell>
                        <div>
                          <CloudDownload
                            onClick={(e) => handleOnSubmit("ginkgo_ewht")}
                          />
                          <span style={{ marginLeft: "10px" }}>
                            เรียงตามวันที่แจ้งโอน
                          </span>
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="text-center">
                        ข้อมูลซื้อขายตามใบสั่งซื้อ
                      </TableCell>
                      <TableCell>
                        <div>
                          <CloudDownload
                            onClick={(e) => handleOnSubmit("tradeorder")}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="text-center">
                        ข้อมูลซื้อขายแยกตามการจัดส่ง
                      </TableCell>
                      <TableCell>
                        <div>
                          <CloudDownload
                            onClick={(e) => handleOnSubmit("tradetrandsport")}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="text-center">
                        รายงานลูกหนี้แยกตามใบแจ้งหนี้
                      </TableCell>
                      <TableCell>
                        <div>
                          <CloudDownload
                            onClick={(e) => handleOnSubmit("due_date")}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="text-center">
                        รายงานลูกหนี้แยกตามรายชีื่อลูกหนี้
                      </TableCell>
                      <TableCell>
                        <div>
                          <CloudDownload
                            onClick={(e) => handleOnSubmit("due_date_shipper")}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  </Table>
                </Grid>
              </Grid> */}
          </form>
        </Container>
      </div>
    </>
  );
};

export default ExportData;
