import instance from "../../axiosInstance";

const getDashboardWork = async (param) => {
  try {
    return instance.get(`/api/new-dashboard/work`, {
      baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
      params: param,
    });
  } catch (error) {
    console.log("error", error);
  }
};

const getDashboardWorkByProfile = async (param) => {
  try {
    return instance.get(`/api/new-dashboard/work-by-profile`, {
      baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
      params: param,
    });
  } catch (error) {
    console.log("error", error);
  }
};

const getDashboardKPIInbound = async (param) => {
  try {
    return instance.get(`/api/new-dashboard/kpi-inbound`, {
      baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
      params: param,
    });
  } catch (error) {
    console.log("error", error);
  }
};

const getDashboardKPIDelivery = async (param) => {
  try {
    return instance.get(`/api/new-dashboard/kpi-delivery`, {
      baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
      params: param,
    });
  } catch (error) {
    console.log("error", error);
  }
};

const getDashboardKPIDocument = async (param) => {
  try {
    return instance.get(`/api/new-dashboard/kpi-document`, {
      baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
      params: param,
    });
  } catch (error) {
    console.log("error", error);
  }
};

const getDashboardKPIIn24hr = async (param) => {
  try {
    return instance.get(`/api/new-dashboard/kpi-in24hr`, {
      baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
      params: param,
    });
  } catch (error) {
    console.log("error", error);
  }
};

const getDashboardKPIMatching = async (param) => {
  try {
    return instance.get(`/api/new-dashboard/kpi-matching`, {
      baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
      params: param,
    });
  } catch (error) {
    console.log("error", error);
  }
};

const getProfileByGrade = async (param) => {
  try {
    return instance.get(`/api/new-dashboard/get-profile-by-grade`, {
      baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
      params: param,
    });
  } catch (error) {
    console.log("error", error);
  }
};

const downloadTagetProfile = (profileID) => {
  try {
    return instance.get(`/api/new-dashboard/target-profile/${profileID}`, {
      baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
      responseType: "blob",
    });
  } catch (error) {
    console.log("error", error);
  }
};

const uploadTaget = (body) => {
  try {
    return instance.post(`/api/new-dashboard/upload-target`, body, {
      baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
      headers: {
        "content-type": "multipart/form-data",
      },
    });
  } catch (error) {
    console.log("error", error);
  }
};
const downloadTaget = () => {
  try {
    return instance.get(`/api/new-dashboard/target`, {
      baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
      responseType: "blob",
    });
  } catch (error) {
    console.log("error", error);
  }
};

const uploadTagetProfile = (profileID, body) => {
  try {
    return instance.post(
      `/api/new-dashboard/upload-target-profile/${profileID}`,
      body,
      {
        baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
  } catch (error) {
    console.log("error", error);
  }
};

const getDataGraphByGroup = async (param) => {
  try {
    return instance.get(`/api/new-dashboard/get-graph-by-group`, {
      baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
      params: param,
    });
  } catch (error) {
    console.log("error", error);
  }
};

const getListTruckByGroup = async (param) => {
  try {
    return instance.get(`/api/new-dashboard/get-list-truck-by-group`, {
      baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
      params: param,
    });
  } catch (error) {
    console.log("error", error);
  }
};

export {
  getDashboardWork,
  getDashboardWorkByProfile,
  getDashboardKPIInbound,
  getDashboardKPIDelivery,
  getDashboardKPIDocument,
  getDashboardKPIIn24hr,
  getDashboardKPIMatching,
  getProfileByGrade,
  downloadTagetProfile,
  uploadTagetProfile,
  downloadTaget,
  uploadTaget,
  getDataGraphByGroup,
  getListTruckByGroup,
};
