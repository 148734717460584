// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DetailUser_containerDetailUser__ib-O1 {
  display: flex;
  flex-direction: row;
  overflow: auto;
  margin: 0;
  gap: 20px;
  padding-left: 97px;
  padding-right: 97px;
}

.DetailUser_cardDetail__ASfhd {
  background-color: white;
  border-radius: 20px;
  width: 400px;
  min-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 25px 10px 25px;
  min-height: 88vh;
  max-height: 88vh;
  overflow: auto;
  margin-bottom: 20px;
  /* height: 100%; */
}

.DetailUser_titleUserRole__F-7hS {
  width: 100%;
  padding: 13px;
  text-align: center;
  border-bottom: 2.5px solid var(--mui-palette-grey-main);
}
`, "",{"version":3,"sources":["webpack://./src/pages/user/DetailUser.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,cAAc;EACd,SAAS;EACT,SAAS;EACT,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,4BAA4B;EAC5B,gBAAgB;EAChB,gBAAgB;EAChB,cAAc;EACd,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,kBAAkB;EAClB,uDAAuD;AACzD","sourcesContent":[".containerDetailUser {\n  display: flex;\n  flex-direction: row;\n  overflow: auto;\n  margin: 0;\n  gap: 20px;\n  padding-left: 97px;\n  padding-right: 97px;\n}\n\n.cardDetail {\n  background-color: white;\n  border-radius: 20px;\n  width: 400px;\n  min-width: 400px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 10px 25px 10px 25px;\n  min-height: 88vh;\n  max-height: 88vh;\n  overflow: auto;\n  margin-bottom: 20px;\n  /* height: 100%; */\n}\n\n.titleUserRole {\n  width: 100%;\n  padding: 13px;\n  text-align: center;\n  border-bottom: 2.5px solid var(--mui-palette-grey-main);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerDetailUser": `DetailUser_containerDetailUser__ib-O1`,
	"cardDetail": `DetailUser_cardDetail__ASfhd`,
	"titleUserRole": `DetailUser_titleUserRole__F-7hS`
};
export default ___CSS_LOADER_EXPORT___;
