import { Modal, Typography } from "@mui/material";
import ButtonCustom from "components/ButtonCustom";
import Loading from "components/loading/Loading";
import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  downloadTaget,
  downloadTagetProfile,
  uploadTaget,
  uploadTagetProfile,
} from "utils/api/dashboard/newDashboard";
import helper from "utils/helper";
import styles from "./ModalAddTarget.module.css";
import CloseIcon from "@mui/icons-material/Close";
import FieldUpload from "components/field/FieldUpload";

const ModalAddTarget = ({
  type = "", //required target,target_profile
  profile_id,
  open = false,
  handleClose = () => {},
}) => {
  const [loading, setLoading] = useState(false);
  const [errorsMessage, setErrorsMessage] = useState([]);
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setValue,
    getValues,
    setError,
    clearErrors,
    trigger,
  } = useForm();
  console.log("errorsMessage", errorsMessage);
  const handleDownload = async () => {
    if (type === "target") {
      const res = await downloadTaget();
      if (res) {
        helper.downloadFileFromResponse(res);
      }
    } else {
      const res = await downloadTagetProfile(profile_id);
      if (res) {
        helper.downloadFileFromResponse(res);
      }
    }
  };

  const handleUpload = async (data) => {
    if (type === "target") {
      setLoading(true);
      const GForm = new FormData();
      Object.keys(data).forEach((key) => {
        GForm.append(key, data[key]);
      });
      try {
        await uploadTaget(GForm).then((res) => {
          if (res.data.code === 1000) {
            setLoading(false);
            handleClose();
          }
        });
      } catch (error) {
        console.log(error);
        setErrorsMessage(error.response.data.description);
        setLoading(false);
      }
    } else {
      setLoading(true);
      const GForm = new FormData();
      Object.keys(data).forEach((key) => {
        GForm.append(key, data[key]);
      });
      try {
        await uploadTagetProfile(profile_id, GForm).then((res) => {
          if (res.data.code === 1000) {
            setLoading(false);
            handleClose();
          }
        });
      } catch (error) {
        console.log(error);
        setErrorsMessage(error.response.data.description);
        setLoading(false);
      }
    }
  };

  return (
    <div>
      {loading && <Loading loading={loading} />}
      <Modal
        open={open}
        onClose={(event, reason) =>
          reason !== "backdropClick" ? handleClose() : ""
        }
        sx={{ zIndex: 1000 }}
      >
        <div className={styles.modalStyle}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              cursor: "pointer",
            }}
          >
            <CloseIcon sx={{ color: "grey.main" }} onClick={handleClose} />
          </div>
          <Typography
            sx={{
              textAlign: "center",
              fontSize: 20,
              fontWeight: 500,
              paddingBottom: "10px",
            }}
          >
            อัพเดตเป้าหมาย
          </Typography>
          <ButtonCustom
            name="ดาวน์โหลดข้อมูลปัจจุบัน"
            onClick={handleDownload}
            type="cancel"
            fullWidth
          />
          <form id="form-content" onSubmit={handleSubmit(handleUpload)}>
            <Controller
              control={control}
              name="file"
              render={({ field: { onChange, value } }) => (
                <div style={{ paddingTop: "20px" }}>
                  <FieldUpload
                    accept=".xlsx"
                    name={
                      type === "target"
                        ? "ไฟล์เป้าหมาย"
                        : "ไฟล์เป้าหมายของลูกค้า"
                    }
                    style={{ width: "-webkit-fill-available" }}
                    value={value}
                    onChange={onChange}
                  />
                </div>
              )}
            />
            <ButtonCustom name="อัพโหลด" type="submit" fullWidth />
          </form>
          {errorsMessage.length > 0 && (
            <div
              style={{
                paddingTop: "20px",
                display: "flex",
                overflow: "auto",
                height: "30vh",
                flexDirection: "column",
              }}
            >
              {errorsMessage.map((val) => {
                return <div style={{ color: "red" }}>{val}</div>;
              })}
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default ModalAddTarget;
