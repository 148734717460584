import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  LabelList,
  Legend,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { MdCheckCircle, MdCancel } from "react-icons/md";

const GraphKPI = ({
  data = null,
  title = "",
  maxValueY = 100,
  onClickBar = () => {},
  dataKeyX = "",
  dataKeyBar = "",
  height = 450,
  width = 730,
  barColor = "#00C49F",
  lineColor = `rgba(0,136,254,0.75)`,
}) => {
  console.log("data", data);
  const [visibleTarget, setVisibleTarget] = useState(true);
  const lastestBar = data ? data[data?.length - 1] : [];
  const formatNumber = (number) =>
    number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const CustomLegend = (props) => {
    const { payload } = props;
    const _barColor = barColor;
    return (
      <div style={{ display: "flex" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: 30,
            width: "100%",
          }}
        >
          {payload.map((entry, index) => (
            <div key={index} style={{ display: "flex", alignItems: "center" }}>
              {entry.value === "percentage" &&
              title === "KPI สะสมรวมทุกด้าน" ? (
                <div style={{ display: "flex" }}>
                  {data.map((value) => {
                    return (
                      <div
                        style={{
                          width: 10,
                          height: 10,
                          backgroundColor: `${value.barColor}1A`,
                          marginRight: 5,
                          borderRadius: 50,
                          border: `1px solid ${value.barColor}`,
                        }}
                      ></div>
                    );
                  })}
                </div>
              ) : (
                <div
                  style={{
                    width: 10,
                    height: 10,
                    backgroundColor: entry.color,
                    marginRight: 5,
                    borderRadius: 50,
                    border:
                      entry.value !== "target"
                        ? `1px solid ${_barColor}`
                        : `1px solid ${entry.color}`,
                  }}
                ></div>
              )}
              <span>
                {entry.value === "target"
                  ? "เป้าหมาย"
                  : entry.value === "percentage"
                  ? "ทำได้"
                  : ""}
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const CustomTooltip = ({ payload, label, active }) => {
    if (!active || !payload || payload.length === 0) return null;

    return (
      <div
        style={{
          display: "flex",
          alignItems: "start",
          background: "white",
          borderRadius: 10,
          border: "1px solid black",
          padding: 5,
          flexDirection: "column",
        }}
      >
        <div style={{ fontSize: 10 }}>{payload[0].payload.xname}</div>
        {payload.map((entry, index) => (
          <div key={index} style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: 10,
                height: 10,
                backgroundColor:
                  entry.payload?.barColor && entry.name === "percentage"
                    ? `${entry.payload?.barColor}1A`
                    : entry.color && entry.name === "target"
                    ? entry.color
                    : `${barColor}1A`,
                border: `1px solid ${
                  entry.payload?.barColor && entry.name === "percentage"
                    ? entry.payload?.barColor
                    : entry.color && entry.name === "target"
                    ? entry.color
                    : barColor
                }`,
                marginRight: 5,
                borderRadius: 50,
              }}
            ></div>
            <span style={{ fontSize: 10 }}>
              {entry.name === "target"
                ? "เป้าหมาย : "
                : entry.name === "percentage"
                ? `ทำได้ : `
                : entry.name + " : "}
              {formatNumber(entry.value) + " %"}
            </span>
          </div>
        ))}

        <div style={{ fontSize: 10, paddingLeft: 15 }}>
          {"ส่วนต่าง : " +
            (
              Number(payload[0]?.payload?.percentage) -
              Number(payload[0]?.payload?.target)
            ).toFixed(2) +
            " %"}
        </div>
        {title === "KPI จับคู่งานสำเร็จ" ||
        payload[0].payload.xname === "Matching" ? (
          <div>
            <div style={{ fontSize: 10, paddingLeft: 15 }}>
              {"สำเร็จ : " + formatNumber(payload[0]?.payload?.count_work)}
            </div>
            <div style={{ fontSize: 10, paddingLeft: 15 }}>
              {"ไม่สำเร็จ : " +
                formatNumber(payload[0]?.payload?.count_expired)}
            </div>
          </div>
        ) : (
          <div>
            <div style={{ fontSize: 10, paddingLeft: 15 }}>
              {"on-time : " + formatNumber(payload[0]?.payload?.kpi_ontime)}
            </div>
            <div style={{ fontSize: 10, paddingLeft: 15 }}>
              {"delay : " + formatNumber(payload[0]?.payload?.kpi_delay)}
            </div>
          </div>
        )}
      </div>
    );
  };

  const CustomBarShape = (props) => {
    const { x, y, width, height, payload } = props;
    const borderRadius = 5;
    const borderColor = barColor;
    const borderWidth = 1;
    if (height <= 0) {
      return null;
    }
    return (
      <path
        d={`
            M${x},${y + height} 
            L${x},${y + borderRadius} 
            Q${x},${y} ${x + borderRadius},${y} 
            L${x + width - borderRadius},${y} 
            Q${x + width},${y} ${x + width},${y + borderRadius} 
            L${x + width},${y + height} 
            Z
          `}
        fill={payload.barColor ? `${payload.barColor}1A` : `${barColor}1A`}
        stroke={payload.barColor ? payload.barColor : barColor}
        strokeWidth={borderWidth}
      />
    );
  };

  return (
    <Paper
      style={{
        backgroundColor: "white",
        padding: "15px",
        borderRadius: "10px",
        height: "100%",
        boxShadow: "none",
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          fontSize={18}
          style={{
            textAlign: "center",
            marginBottom: "15px",
            fontWeight: 500,
          }}
        >
          {title}
        </Typography>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {title !== "KPI สะสมรวมทุกด้าน" && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingRight: 5,
              }}
            >
              {lastestBar?.count === 0 || lastestBar?.count_work === 0 ? (
                ""
              ) : Number(lastestBar?.percentage) >=
                Number(lastestBar?.target) ? (
                <MdCheckCircle color="#27CE88" size={20} />
              ) : (
                <MdCancel color="#FF316F" size={20} />
              )}
            </div>
          )}
          <Typography
            fontSize={18}
            style={{
              textAlign: "end",
              fontWeight: 500,
              color:
                lastestBar?.count === 0 || lastestBar?.count_work === 0
                  ? "black"
                  : Number(lastestBar?.percentage) >= Number(lastestBar?.target)
                  ? "#27CE88"
                  : "#FF316F",
            }}
          >
            {title !== "KPI สะสมรวมทุกด้าน"
              ? lastestBar?.count === 0 || lastestBar?.count_work === 0
                ? " - "
                : Number(lastestBar?.percentage) >= Number(lastestBar?.target)
                ? "ผ่าน"
                : "ไม่ผ่าน"
              : null}
          </Typography>
        </div>
      </div>

      <ResponsiveContainer width="100%" height={height}>
        <ComposedChart
          width={width}
          height={height}
          data={data}
          margin={{
            top: 70,
            right: 30,
          }}
        >
          <XAxis
            dataKey={dataKeyX}
            style={{ fontSize: "10px", fontWeight: 500 }}
          />
          <YAxis
            style={{ fontSize: "10px", fontWeight: 500 }}
            tickFormatter={formatNumber}
            domain={[0, maxValueY]}
          />
          <Tooltip content={<CustomTooltip />} />
          <Legend content={<CustomLegend />} />
          <CartesianGrid stroke="#f5f5f5" />
          <Bar
            dataKey={dataKeyBar}
            barSize={20}
            // fill={({ payload }) => {
            //   const color = barColor;
            //   console.log("colooor", color);
            //   return color + "1A";
            // }}
            // fill={`${barColor}1A`}
            onClick={(data, index) => onClickBar(data.payload)}
            shape={<CustomBarShape />}
          >
            <LabelList
              dataKey="label"
              content={(props) => {
                const { x, y, value, width, target } = props;
                const lines =
                  title === "KPI สะสมรวมทุกด้าน" && value
                    ? value.split("\n")
                    : "";
                return (
                  <text
                    x={x + 2}
                    y={y + 8}
                    fill={
                      title === "KPI สะสมรวมทุกด้าน"
                        ? value?.includes("ไม่ผ่าน")
                          ? "#FF316F"
                          : value?.includes("-")
                          ? "#FFC675"
                          : "#27CE88"
                        : typeof value !== "string"
                        ? "#FFC675"
                        : Number(value) >= Number(target)
                        ? "#27CE88"
                        : "#FF316F"
                    }
                    textAnchor="top"
                    fontSize="14px"
                    fontWeight="500"
                    transform={`rotate(-90,  ${x}, ${y})`}
                  >
                    {title === "KPI สะสมรวมทุกด้าน"
                      ? lines &&
                        lines?.map((line, index) => (
                          <tspan
                            key={index}
                            x={x + 2}
                            dy={index * 12}
                            style={{
                              fontSize: index == 1 ? "12px" : "14px",
                            }}
                          >
                            {line}
                          </tspan>
                        ))
                      : typeof value !== "string"
                      ? "-"
                      : value + " %"}
                  </text>
                );
              }}
            />
          </Bar>
          {data && data[0]?.target && (
            <Line
              dot={false}
              strokeWidth={2}
              dataKey="target"
              stroke={lineColor}
              strokeDasharray="5 5"
            />
          )}
        </ComposedChart>
      </ResponsiveContainer>
    </Paper>
  );
};

export default GraphKPI;
