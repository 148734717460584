import { Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import styles from "./TableTask.module.css";

export const TaskStatus = [
  { value: 1, name: "ยังไม่เริ่มดำเนินการ" },
  { value: 2, name: "รับทราบงาน" },
  { value: 3, name: "กําลังดําเนินการ" },
  { value: 4, name: "เสร็จสิ้น" },
  { value: 5, name: "ยกเลิกงาน" },
];
const ModalTaskStatusLog = (props) => {
  return (
    <div>
      <Modal
        open={props?.open}
        onClose={(event, reason) =>
          reason !== "backdropClick" ? props?.handleClose : ""
        }
        sx={{ zIndex: 1000 }}
      >
        <div className={styles.modalStyle}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <CloseIcon
              sx={{ color: "grey.main" }}
              onClick={props?.handleClose}
            />
          </div>
          <div
            style={{
              fontSize: 20,
              fontWeight: 400,
              textAlign: "center",
              marginBottom: 10,
            }}
          >
            ประวัติการเปลี่ยนสถานะ
          </div>

          {props?.data?.map((ele, index) => (
            <div
              key={index}
              style={{ paddingBottom: 10, borderBottom: "1px solid #121f431A" }}
            >
              <div style={{ fontSize: 16, fontWeight: 400 }}>
                {
                  TaskStatus.find((item) => item.value === ele?.task_status)
                    ?.name
                }
              </div>
              <div style={{ fontSize: 12, fontWeight: 300 }}>
                {ele?.actual_date}
              </div>
              <div style={{ fontSize: 12, fontWeight: 300 }}>{ele?.detail}</div>
            </div>
          ))}
        </div>
      </Modal>
    </div>
  );
};

export default ModalTaskStatusLog;
