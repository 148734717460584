import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as R from "ramda";
import { Container } from "@mui/material";
import SubMenu from "components/layout/SubMenu.js";
import FilterTab from "components/filterTab/FilterTab";
import {
  downloadExcelRouteStlStandardSize,
  getAllRouteStlStandardSize,
  uploadExcelRouteStlStandardSize,
} from "utils/api/masterData/routeStlStandardSize";
import { getAllZone } from "utils/api/masterData/gis";
import { subMenuMasterData } from "utils/submenuoptions";
import TableActiveIcon from "components/tableActiveIcon";
import TableCustom from "components/table/TableCustom";
import FieldSelect from "components/field/FieldSelect";
import FieldDateRangePicker from "components/field/FieldDateRangePicker";
import Loading from "components/loading/Loading";
import AlertModal from "components/modal/AlertModal";
import helper from "utils/helper";

const columns = [
  {
    id: "created_at",
    label: "วันที่สร้างรายการ",
    align: "center",
    format: (value) => {
      return helper.ToConvertDateTimeForTable(value.created_at);
    },
  },
  {
    id: "updated_at",
    label: "วันที่แก้ไขข้อมูลล่าสุด",
    align: "center",
    format: (value) => {
      return helper.ToConvertDateTimeForTable(value.updated_at);
    },
  },
  {
    id: "load_zone_name",
    label: "ภูมิภาคจุดรับสินค้า",
    align: "center",
    format: (value) => {
      return value.load_zone_name;
    },
  },
  {
    id: "unload_zone_name",
    label: "ภูมิภาคจุดส่งสินค้า",
    align: "center",
    format: (value) => {
      return value.unload_zone_name;
    },
  },
  {
    id: "active",
    label: "สถานะใช้งาน",
    align: "center",
    format: (value) => {
      return <TableActiveIcon active_status={value.active} />;
    },
  },
];

const RouteStlStandardSize = () => {
  const navigate = useNavigate();
  const initial_filter = {
    sort_field: [`updated_at`],
    sort_order: [-1],
    start_date: ``,
    end_date: ``,
    load_zone: `all`,
    unload_zone: `all`,
    search_field: `province`,
    search_val: ``,
  };

  const [routeStlStandardSize, setRouteStlStandardSize] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 5,
    total: 0,
  });
  // const [allProvince, setAllProvince] = useState([]);
  const [allZone, setAllZone] = useState([]);
  const [filter, setFilter] = useState(initial_filter);
  const [params, setParams] = useState({});
  const [alertModal, setAlertModal] = useState({
    open: false,
    title: ``,
    description: ``,
  });

  useEffect(() => {
    handleGetDataByNewParams({ page: 1 });
    handleGetAllProvince();

    // eslint-disable-next-line
  }, []);

  const handleGetDataByNewParams = ({
    page = pagination.page,
    per_page = pagination.per_page,
    type = `getAllRouteStlStandardSize`,
    file_upload = null,
  }) => {
    let params = {
      start_date:
        !R.isNil(filter.start_date) && !R.isEmpty(filter.start_date)
          ? new Date(filter.start_date).toISOString()
          : null,
      end_date:
        !R.isNil(filter.end_date) && !R.isEmpty(filter.end_date)
          ? new Date(filter.end_date).toISOString()
          : null,
      load_zone: filter.load_zone === `all` ? null : filter.load_zone,
      unload_zone: filter.unload_zone === `all` ? null : filter.unload_zone,
      sort_field: filter.sort_field,
      sort_order: filter.sort_order,
      [filter.search_field]: filter.search_val,
      page: page,
      per_page: per_page,
    };

    if (type === `getAllRouteStlStandardSize`) {
      handleGetAllRouteStlStandardSize({
        page: page,
        per_page: per_page,
        new_params: params,
      });
    } else if (type === `downloadRouteStlStandardSizeExcel`) {
      params = {
        ...params,
        sort_field: ["id"],
        sort_order: [1],
      };
      handleDownloadRouteProvinceExcel({
        page: null,
        per_page: null,
        new_params: params,
      });
    } else if (type === `uploadRouteStlStandardSizeExcel`) {
      if (!R.isNil(file_upload)) {
        handleUploadRouteStlStandardSizeExcel({
          file: file_upload,
        });
      }
    }

    setParams(params);
  };

  const handleUploadRouteStlStandardSizeExcel = async ({ file = null }) => {
    setIsFileLoading(true);
    try {
      const is_excel_file = helper.isCsvOrXlsxfile({ file: file });
      if (is_excel_file === true) {
        const upload_response = await uploadExcelRouteStlStandardSize({
          route_stl_standard_size_file: file,
        });
        if (upload_response.data.code === 1000) {
          setAlertModal({
            open: true,
            title: `อัพโหลดสำเร็จ`,
            description: `อัพโหลดข้อมูลสำเร็จ`,
          });
        }
      } else {
        setAlertModal({
          open: true,
          title: `พบปัญหาขณะอัพโหลดไฟล์`,
          description: `กรุณาใส่ไฟล์ .csv | .xlsx | .xls`,
        });
      }

      setIsFileLoading(false);
    } catch (error) {
      setIsFileLoading(false);
      setAlertModal({
        open: true,
        title: `พบปัญหาขณะอัพโหลดไฟล์`,
        description: error.response.data.description,
      });
      console.log(`error handleUploadRouteStlStandardSizeExcel:`, error);
    }
  };

  const handleDownloadRouteProvinceExcel = async ({
    page = pagination.page,
    per_page = pagination.per_page,
    new_params = null,
  }) => {
    setIsFileLoading(true);
    try {
      if (R.isNil(new_params)) {
        new_params = { ...params, page: null, per_page: null };
      } else {
        new_params = { ...new_params, page: null, per_page: null };
      }

      const download_response = await downloadExcelRouteStlStandardSize(
        new_params
      );
      if (download_response.status === 200) {
        helper.downloadFile({
          api_response: download_response,
        });
      }

      setIsFileLoading(false);
    } catch (error) {
      setIsFileLoading(false);
      console.log(`error handleDownloadRouteProvinceExcel:`, error);
    }
  };

  const handleGetAllRouteStlStandardSize = async ({
    page = pagination.page,
    per_page = pagination.per_page,
    new_params = null,
  }) => {
    setIsLoading(true);
    try {
      if (R.isNil(new_params)) {
        new_params = { ...params, page: page, per_page: per_page };
      }

      const resp = await getAllRouteStlStandardSize(new_params);
      setRouteStlStandardSize(resp.data.data.results);
      setPagination({
        page: page,
        per_page: per_page,
        total: resp.data.data.total,
      });
      setIsLoading(false);
    } catch (error) {
      console.log("error getAllRouteStlStandardSize: ", error);
      setIsLoading(false);
      setAlertModal({
        open: true,
        title: `เกิดข้อผิดพลาด`,
        description: error.response.data.description,
      });
    }
  };

  const handleGetAllProvince = async () => {
    try {
      const resp = await getAllZone();
      const _all_zone = [
        {
          name: "all",
        },
        ...resp.data.data.results,
      ];
      setAllZone(_all_zone);
    } catch (error) {
      console.log("error getAllZone: ", error);
      // setAllZone([]);
    }
  };

  const onChangeFilter = (field_type = `sort`, val = ``) => {
    if (field_type === `sort`) {
      setFilter({ ...filter, sort_field: [val], sort_order: [-1] });
    } else if (field_type === `date_duration`) {
      const start_date = val[0] ? helper.toGMTPlus7(val[0]) : ``;
      const end_date = val[1] ? helper.toGMTPlus7(val[1]) : ``;

      setFilter({ ...filter, start_date: start_date, end_date: end_date });
    } else {
      setFilter({ ...filter, [field_type]: val });
    }
  };

  const renderSubMenu = () => {
    return (
      <SubMenu
        label={`เส้นทางภูมิภาคที่ให้บริการ (STL-Size&Freight)`}
        menus={subMenuMasterData.map((menu) => {
          return {
            label: menu.label,
            path: menu.path,
            active:
              menu.label === `เส้นทางภูมิภาคที่ให้บริการ (STL-Size&Freight)`
                ? true
                : false,
            header: menu.header,
          };
        })}
        breadcrumbs={[
          {
            label: "จัดการ Master Data",
            path: null,
          },
          {
            label: "เส้นทางภูมิภาคที่ให้บริการ (STL-Size&Freight)",
            path: null,
          },
        ]}
      />
    );
  };

  const renderFilterTab = () => {
    return (
      <FilterTab
        sortOption={{
          value: "updated_at",
          options: [{ value: "updated_at", name: "วันที่แก้ไขข้อมูลล่าสุด" }],
          onChange: (field_type, val) => {
            onChangeFilter(field_type, val);
          },
        }}
        dateRangePickerOption={{
          value: {
            start_date: filter.start_date,
            end_date: filter.end_date,
          },
          onChange: (field_type, val) => {
            onChangeFilter(field_type, val);
          },
        }}
        // searchValueOption={{
        //   onPressEnter: (field_type, val) => {
        //     handleGetAllRouteStlStandardSize({
        //       update_pagination: false,
        //       back_to_first_page: true,
        //     });
        //   },
        //   onChange: (field_type, val) => {
        //     onChangeFilter(field_type, val);
        //   },
        // }}
        // searchFieldOption={{
        //   value: filter.search_field,
        //   options: [{ value: "province", name: "ชื่อจังหวัด" }],
        //   onChange: (field_type, val) => {
        //     onChangeFilter(field_type, val);
        //   },
        // }}
        downloadButtonOption={{
          onClick: () => {
            handleGetDataByNewParams({
              type: `downloadRouteStlStandardSizeExcel`,
            });
          },
        }}
        uploadButtonOption={{
          onClick: (file) => {
            if (!R.isNil(file)) {
              const is_excel_file = helper.isCsvOrXlsxfile({ file: file });
              handleGetDataByNewParams({
                type: `uploadRouteStlStandardSizeExcel`,
                file_upload: file,
              });
            }
          },
        }}
        searchButtonOption={{
          onChange: (field_type, val) => {
            onChangeFilter(field_type, val);
          },
          onClick: () => {
            handleGetDataByNewParams({ page: 1 });
          },
        }}
        perPageSelectedOption={{
          value: pagination.per_page,
          onChange: (value) => {
            handleGetDataByNewParams({ page: 1, per_page: value });
          },
        }}
        advanceSearchButtonOption={{
          onSearch: () => {
            handleGetDataByNewParams({ page: 1 });
          },
          onReset: () => {
            setFilter(initial_filter);
          },
          children: advanceSearchForm(),
        }}
      />
    );
  };

  const advanceSearchForm = () => {
    return (
      <div>
        <FieldSelect
          name="กรองและจัดเรียงตาม"
          value={"updated_at"}
          options={[
            {
              value: "updated_at",
              name: "วันที่แก้ไขข้อมูลล่าสุด",
            },
          ]}
          onChange={(val) => {
            onChangeFilter("updated_at", val);
          }}
          style={{ width: `100%` }}
        />

        <FieldDateRangePicker
          name={`วันเริ่มต้น - วันสิ้นสุด`}
          popupClassName="FieldDateRangePicker-drawer-popup"
          value={{
            start_date: filter.start_date,
            end_date: filter.end_date,
          }}
          onChange={(val) => {
            onChangeFilter(`date_duration`, val);
          }}
          style={{ width: `100%`, marginBottom: 26 }}
        />

        <FieldSelect
          name="ภูมิภาคจุดรับสินค้า"
          value={filter.load_zone}
          options={allZone.map((zone) => {
            return {
              value: zone.name,
              name: zone.name === `all` ? "ทั้งหมด" : zone.name,
            };
          })}
          onChange={(val) => {
            onChangeFilter("load_zone", val);
          }}
          style={{ width: `100%` }}
        />

        <FieldSelect
          name="ภูมิภาคจุดส่งสินค้า"
          value={filter.unload_zone}
          options={allZone.map((zone) => {
            return {
              value: zone.name,
              name: zone.name === `all` ? "ทั้งหมด" : zone.name,
            };
          })}
          onChange={(val) => {
            onChangeFilter("unload_zone", val);
          }}
          style={{ width: `100%` }}
        />
      </div>
    );
  };

  const renderTable = () => {
    return (
      <TableCustom
        columns={columns}
        dataSource={routeStlStandardSize}
        pagination={pagination}
        loading={isLoading}
        setPagination={(val) => {
          handleGetAllRouteStlStandardSize({
            page: val.page,
            per_page: val.per_page,
          });
        }}
        // onSelectedRow={(val) => console.log(val)}
        href={`/master-data/route-stl-standard-size/`}
        lastParamsVariable={`route_zone_id`}
      />
    );
  };

  const renderModal = () => {
    return alertModal.open ? (
      <AlertModal
        open={alertModal.open}
        title={alertModal.title}
        description={alertModal.description}
        onClose={() => {
          if (alertModal.description === "ไม่มีสิทธ์ในการเข้าถึง") {
            navigate("/");
          }

          setAlertModal({
            open: false,
            title: ``,
            description: ``,
          });
        }}
      />
    ) : null;
  };

  return (
    <Container>
      {renderSubMenu()}
      {renderFilterTab()}
      {isFileLoading === true ? <Loading /> : renderTable()}
      {renderModal()}
    </Container>
  );
};

export default RouteStlStandardSize;
