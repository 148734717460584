// ==============================|| PRESET THEME - THEME SELECTOR ||============================== //

const Theme = () => {
  const greyColors = { main: "#8CA3BA" };
  const contrastText = "#fff";

  return {
    primary: {
      main: "#121F43",
      contrastText,
    },
    secondary: {
      main: "#121F43",
    },
    error: {
      main: "#FF316F",
      contrastText,
    },
    warning: {
      main: "#FFC675",
      contrastText,
    },
    success: {
      main: "#27CE88",
      contrastText,
    },
    grey: greyColors,

    whenhover: "rgba(0, 0, 0, 0.04)",

    purple_theme: {
      main: "#8C8DFF",
      contrastText,
    },
    orange_theme: "#F9A38A",

    grey_badge: { main: "#8CA3BA", contrastText },
  };
};

export default Theme;
