import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./pages/auth/Login";
import ForgetPassword from "./pages/auth/ForgetPassword";
import PrivateRoutes from "./utils/PrivateRoutes";
import User from "./pages/User";
import Home from "pages/home/Home";
import Truck from "pages/truck/Truck";
import TruckInfo from "pages/truck/TruckInfo";
import DashbordWork from "./pages/dashboard/DashboardWork";
import DashbordIncome from "pages/dashboard/DashboardIncome";
import DashboardProfile from "pages/dashboard/DashboardProfile";
import DashboardTruck from "pages/dashboard/DashboardTruck";
import TruckType from "pages/masterData/truckType/TruckType";
import TruckTypeInfo from "pages/masterData/truckType/TruckTypeInfo";
import OrderTruckType from "pages/masterData/truckType/OrderTruckType";
import TableAdmin from "pages/admin/TableAdmin";
import ShipperUnpiad from "pages/finance/shipper/Unpaid";
import ShipperInvoice from "pages/finance/shipper/Invoice";
import ShipperBillingnote from "pages/finance/shipper/BillingNote";
import ShipperReceipt from "pages/finance/shipper/Receipt";
import ResetPassword from "pages/auth/ResetPassword";
import DetailAdmin from "pages/admin/DetailAdmin";
import PostWork from "pages/work/PostWork";
import TableUser from "pages/user/TableUser";
import FuelRate from "pages/masterData/fuelRate/FuelRate";
import FuelRateInfo from "pages/masterData/fuelRate/FuelRateInfo";
import ShipperInvoiceGroup from "pages/finance/shipper/InvoiceGroup";
import ShipperBillingNoteGroup from "pages/finance/shipper/BillingNoteGroup";
import ShipperReceiptGroup from "pages/finance/shipper/ReceiptGroup";
import DetailUser from "pages/user/DetailUser";
import AnnualInsurance from "pages/masterData/annualInsurance/annualInsurance";
import TruckAccessory from "pages/masterData/accessory/Accessory";
import TruckAccessoryInfo from "pages/masterData/accessory/AccessoryInfo";
import ServiceArea from "pages/masterData/serviceArea/ServiceArea";
import ProductType from "pages/masterData/productType/ProductType";
import ProductTypeInfo from "pages/masterData/productType/ProductTypeInfo";
import OrderProductType from "pages/masterData/productType/OrderProductType";
import ServiceAreaInfo from "pages/masterData/serviceArea/ServiceAreaInfo";
import CarrierUnpiad from "pages/finance/carrier/Unpaid";
import CarrierInvoice from "pages/finance/carrier/Invoice";
import CarrierBillingnote from "pages/finance/carrier/BillingNote";
import CarrierReceipt from "pages/finance/carrier/Receipt";
import CarrierInvoiceGroup from "pages/finance/carrier/InvoiceGroup";
import DetailProfile from "pages/profile/DetailProfile";
import RouteProvince from "pages/masterData/routeProvince/RouteProvince";
import RouteProvinceInfo from "pages/masterData/routeProvince/RouteProvinceInfo";
import RouteStlStandardSize from "pages/masterData/routeStlStandardSize/RouteStlStandardSize";
import RouteStlStandardSizeInfo from "pages/masterData/routeStlStandardSize/RouteStlStandardSizeInfo";
import CarrierBillingNoteGroup from "pages/finance/carrier/BillingNoteGroup";
import CarrierReceiptGroup from "pages/finance/carrier/ReceiptGroup";
import TermAndCondition from "pages/masterData/termAndCondition/TermAndCondition";
import TermAndConditionInfo from "pages/masterData/termAndCondition/TermAndConditionInfo";
import PrivacyPolicy from "pages/masterData/privacyPolicy/PrivacyPolicy";
import PrivacyPolicyInfo from "pages/masterData/privacyPolicy/PrivacyPolicyInfo";
import ImportWork from "pages/work/ImportWork";
import TruckCheckProject from "pages/truckCheckProject/TruckCheckProject";
import TruckCheckProjectInfo from "pages/truckCheckProject/TruckCheckProjectInfo";
import TableSubUserInProfile from "pages/profile/subUser/TableSubUserInProfile";
import DetailSubUserInProfile from "pages/profile/subUser/DetailSubUserInProfile";
import TableAllProjectInProfile from "pages/project/TableAllProjectInProfile";
import AllWork from "pages/work/AllWork";
import DetailProject from "pages/project/DetailProject";
import DriverTrainingProject from "pages/driverTrainingProject/DriverTrainingProject";
import DriverTrainingProjectInfo from "pages/driverTrainingProject/DriverTrainingProjectInfo";
import TableAllProject from "pages/project/TableAllProject";
import TableAllCarrierInProject from "pages/project/carrierInProject/TableAllCarrierInProject";
import WorkDetails from "pages/work/WorkDetails";
import DriverHealthProject from "pages/driverHealthProject/DriverHealthProject";
import DriverHealthProjectInfo from "pages/driverHealthProject/DriverHealthProjectInfo";
import FavoriteShipperGroup from "pages/favoriteShipperGroup/FavoriteShipperGroup";
import FavoriteShipperGroupInfo from "pages/favoriteShipperGroup/FavoriteShipperGroupInfo";
import CarrierInFavoriteGroup from "pages/carrierInFavoriteGroup/CarrierInFavoriteGroup";
import CarrierInFavoriteGroupInfo from "pages/carrierInFavoriteGroup/CarrierInFavoriteGroupInfo";
import DetailCarrierInProject from "pages/project/carrierInProject/DetailCarrierInProject";
import TableProjectAdmin from "pages/project/projectAdmin/TableProjectAdmin";
import ProjectProgress from "pages/project/ProjectProgression";
import ProjectProgressDetail from "pages/project/ProjectProgression/Detail";
import CarrierProgress from "pages/project/CarrierProgression";
import ProfileReceiptName from "pages/profileReceiptName/ProfileReceiptName";
import ProfileReceiptNameInfo from "pages/profileReceiptName/ProfileReceiptNameInfo";
import ParticipateProject from "pages/participateProject/ParticipateProject";
import ParticipateProjectInfo from "pages/participateProject/ParticipateProjectInfo";
import ParticipateRunnerProject from "pages/profilJoinCarrier/ParticipateRunnerProject";
import ProfileJoinCarrierInfo from "pages/profilJoinCarrier/ProfileJoinCarrierInfo";
import CoRunnerProject from "pages/profilJoinCarrier/CoRunnerProject";
import FuelRateQuotation from "pages/fuelRateQuotation/FuelRateQuotation";
import ProjectCarrierProgressDetail from "pages/project/CarrierProgression/Detail";
import DetailProjectAdmin from "pages/project/projectAdmin/DetailProjectAdmin";
import TableFavoritePlace from "pages/profile/favoritePlace/TableFavoritePlace";
import FuelRateQuotationInfo from "pages/fuelRateQuotation/FuelRateQuotationInfo";
import DetailFavoritePlace from "pages/profile/favoritePlace/DetailFavoritePlace";
import TrackingDriver from "pages/work/TrackingDriver";
import ExportData from "pages/dashboard/ExportData";
import WemovedriveVersion from "pages/masterData/appVersion/WemovedriveVersion/WemovedriveVersion";
import WemovedriveVersionInfo from "pages/masterData/appVersion/WemovedriveVersion/WemovedriveVersionInfo";
import WorkPublicDetail from "pages/workPublic/workPublicDetail";
import { useEffect } from "react";
import CountWorkByTruck from "pages/workPublic/CountWorkByTruck";
import PublicWorkCard from "pages/workPublic/PublicWorkCard";
import CheckWorkPrice from "pages/workPublic/CheckWorkPrice";
import TrackingWork from "pages/work/TrackingWork";
import RouteOptimize from "pages/work/RouteOptimize";
import ThirdPartyKey from "pages/masterData/thirdPartyKey/ThirdPartyKey";
import CheckApp from "pages/workPublic/CheckApp";
import Template from "pages/project/template/template";
import DashboardKPI from "pages/dashboard/DashboardKPI";
import CheckOilPrice from "pages/workPublic/CheckOilPrice";
import TableWorkRegular from "pages/workRegular/TableWorkRegular";
import WorkRegularDetail from "pages/workRegular/WorkRegularDetail";
import TemplateDetail from "pages/project/template/templateDetail";
import TpsUnpiad from "pages/finance/tps/Unpaid";
import TpsInvoice from "pages/finance/tps/Invoice";
import TpsInvoiceGroup from "pages/finance/tps/InvoiceGroup";
import TableTrade from "pages/trade/TableTrade";
import TableCustomer from "pages/trade/customer/TableCustomer";
import DetailCustomer from "pages/trade/customer/DetailCustomer";
import TableProjectCustomer from "pages/trade/customer/projectCustomer/TableProjectCustomer";
import TableJobber from "pages/trade/jobber/TableJobber";
import TableProductTradeType from "pages/masterData/productTradeType/TableProductTradeType";
import TradeBuyerUnpiad from "pages/trade/finance/buyer/Unpaid";
import TradeBuyerInvoiceGroup from "pages/trade/finance/buyer/InvoiceGroup";
import TradeBuyerBillingNoteGroup from "pages/trade/finance/buyer/BillingNoteGroup";
import TradeBuyerPayGroup from "pages/trade/finance/buyer/PayGroup";
import TradeSellerUnpiad from "pages/trade/finance/seller/Unpaid";
import TradeSellerInvoiceGroup from "pages/trade/finance/seller/InvoiceGroup";
import TradeSellerBillingNoteGroup from "pages/trade/finance/seller/BillingNoteGroup";
import TradeSellerPayGroup from "pages/trade/finance/seller/PayGroup";
import TradeBuyerInvoice from "pages/trade/finance/buyer/Invoice";
import TradeBuyerBillingNote from "pages/trade/finance/buyer/BillingNote";
import TradeBuyerPay from "pages/trade/finance/buyer/Pay";
import TradeSellerInvoice from "pages/trade/finance/seller/Invoice";
import TradeSellerBillingNote from "pages/trade/finance/seller/BillingNote";
import TradeSellerPay from "pages/trade/finance/seller/Pay";
import TradeCarrierUnpiad from "pages/trade/finance/carrier/Unpaid";
import TradeCarrierInvoice from "pages/trade/finance/carrier/Invoice";
import TradeCarrierInvoiceGroup from "pages/trade/finance/carrier/InvoiceGroup";
import TradeCarrierBillingNote from "pages/trade/finance/carrier/BillingNote";
import TradeCarrierBillingNoteGroup from "pages/trade/finance/carrier/BillingNoteGroup";
import TradeCarrierPay from "pages/trade/finance/carrier/Pay";
import TradeCarrierPayGroup from "pages/trade/finance/carrier/PayGroup";
import WemoveVersionInfo from "pages/masterData/appVersion/WemoveVersion/WemoveVersionInfo";
import WemoveVersion from "pages/masterData/appVersion/WemoveVersion/WemoveVersion";
import TrackingUser from "pages/user/TrackingUser";
import TableTenant from "pages/truckRental/tenant/TableTenant";
import TableProjectTenant from "pages/truckRental/tenant/projectTenant/TableProjectTenant";
import TableProductRentalType from "pages/masterData/productRentalType/TableProductRentalType";
import TableLessor from "pages/truckRental/lessor/TableLessor";
import TableRental from "pages/truckRental/TableRental";
import TruckTenantUnpiad from "pages/truckRental/finance/tenant/Unpaid";
import TruckTenantInvoice from "pages/truckRental/finance/tenant/Invoice";
import TruckTenantInvoiceGroup from "pages/truckRental/finance/tenant/InvoiceGroup";
import TruckTenantBillingNote from "pages/truckRental/finance/tenant/BillingNote";
import TruckTenantBillingNoteGroup from "pages/truckRental/finance/tenant/BillingNoteGroup";
import TruckTenantPay from "pages/truckRental/finance/tenant/Pay";
import TruckTenantPayGroup from "pages/truckRental/finance/tenant/PayGroup";
import TruckLessorUnpiad from "pages/truckRental/finance/lessor/Unpaid";
import TruckLessorInvoice from "pages/truckRental/finance/lessor/Invoice";
import TruckLessorInvoiceGroup from "pages/truckRental/finance/lessor/InvoiceGroup";
import TruckLessorBillingNote from "pages/truckRental/finance/lessor/BillingNote";
import TruckLessorBillingNoteGroup from "pages/truckRental/finance/lessor/BillingNoteGroup";
import TruckLessorPay from "pages/truckRental/finance/lessor/Pay";
import TruckLessorPayGroup from "pages/truckRental/finance/lessor/PayGroup";
import TableTask from "pages/task/TableTask";
import TableTaskGroup from "pages/task/groupTask/TableTaskGroup";
import TableTaskAll from "pages/task/TableTaskAll";
import WorkDetailV2 from "pages/work/WorkDetailsV2";
import EditTransactionBuyer from "pages/trade/finance/buyer/EditTransactionBuyer";
import EditTransactionSeller from "pages/trade/finance/seller/EditTransactionSeller";
import EditTransactionCarrier from "pages/trade/finance/carrier/EditTransactionCarrier";
import EditTransactionLessor from "pages/truckRental/finance/lessor/EditTransactionLessor";
import EditTransactionTenant from "pages/truckRental/finance/tenant/EditTransactionTenant";
import EditWorkTransactionShipper from "pages/finance/shipper/EditWorkTransactionShipper";
import EditWorkTransactionCarrier from "pages/finance/carrier/EditWorkTransactionCarrier";
import NewDashboard from "pages/dashboard/NewDashboard";
import TableIndustry from "pages/masterData/industry/TableIndustry";
import KPI from "pages/dashboard/KPI/KPI";

function App() {
  // useEffect(() => {
  //   const head = document.querySelector("head");
  //   const ogImageMeta = document.createElement("meta");
  //   ogImageMeta.setAttribute("property", "og:image");
  //   ogImageMeta.setAttribute("content", "./assets/image/WemoveLogo.svg");
  //   head.appendChild(ogImageMeta);
  //   return () => {
  //     head.removeChild(ogImageMeta);
  //   };
  // }, []);

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route path="/" element={<Home />} exact />
            <Route path="/user" element={<TableUser />} exact />
            <Route path="/user-tracking" element={<TrackingUser />} exact />
            <Route path="/user/:id" element={<DetailUser />} exact />
            <Route
              path="/user/:user_id/profile/:profile_id"
              element={<DetailProfile />}
              exact
            />
            <Route
              path="/user/:user_id/:user_code/profile/:profile_id/sub-user"
              element={<TableSubUserInProfile />}
              exact
            />
            <Route
              path="/user/:user_id/:user_code/profile/:profile_id/sub-user/detail/:user_id"
              element={<DetailSubUserInProfile />}
              exact
            />
            <Route path="/project" element={<TableAllProject />} exact />
            <Route
              path="/project/detail/:project_id"
              element={<DetailProject />}
              exact
            />
            <Route
              path="/user/:user_id/:user_code/profile/:profile_id/project"
              element={<TableAllProjectInProfile />}
              exact
            />
            <Route
              path="/user/:user_id/:user_code/profile/:profile_id/project/detail/:project_id"
              element={<DetailProject />}
              exact
            />
            <Route
              path="/user/:user_id/:user_code/profile/:profile_id/project/:project_id/:project_code/project-progression/"
              element={<ProjectProgress />}
              exact
            />
            <Route
              path="/user/:user_id/:user_code/profile/:profile_id/project/:project_id/:project_code/template"
              element={<Template />}
              exact
            />
            <Route
              path="/user/:user_id/:user_code/profile/:profile_id/project/:project_id/:project_code/template/:fuel_rate_id"
              element={<TemplateDetail />}
              exact
            />
            <Route
              path="/user/:user_id/:user_code/profile/:profile_id/project/:project_id/:project_code/project-progression/:progression_id"
              element={<ProjectProgressDetail />}
              exact
            />
            <Route
              path="/user/:user_id/:user_code/profile/:profile_id/project/:project_id/:project_code/carrier-progression/"
              element={<CarrierProgress />}
              exact
            />
            <Route
              path="/user/:user_id/:user_code/profile/:profile_id/project/:project_id/:project_code/carrier-progression/:progression_id"
              element={<ProjectCarrierProgressDetail />}
              exact
            />
            <Route
              path="/user/:user_id/:user_code/profile/:profile_id/project/detail/:project_id/:project_code/carrier-in-project"
              element={<TableAllCarrierInProject />}
              exact
            />
            <Route
              path="/user/:user_id/:user_code/profile/:profile_id/project/detail/:project_id/:project_code/carrier-in-project/detail/:carrier_in_project_id"
              element={<DetailCarrierInProject />}
              exact
            />
            <Route
              path="/user/:user_id/:user_code/profile/:profile_id/project/detail/:project_id/:project_code/project-admin"
              element={<TableProjectAdmin />}
              exact
            />
            <Route
              path="/user/:user_id/:user_code/profile/:profile_id/project/detail/:project_id/:project_code/project-admin/detail/:project_admin_id"
              element={<DetailProjectAdmin />}
              exact
            />
            <Route
              path="/user/:user_id/:user_code/profile/:profile_id/favorite-place"
              element={<TableFavoritePlace />}
              exact
            />
            <Route
              path="/user/:user_id/:user_code/profile/:profile_id/favorite-place/detail/:favorite_place_id"
              element={<DetailFavoritePlace />}
              exact
            />
            {/* <Route path="/dashboard/pond" element={<DashbordWork />} exact /> */}
            <Route path="/dashboard/work" element={<NewDashboard />} exact />
            <Route path="/dashboard/KPI" element={<KPI />} exact />
            <Route
              path="/dashboard/income"
              element={<DashbordIncome />}
              exact
            />
            <Route
              path="/dashboard/profile"
              element={<DashboardProfile />}
              exact
            />
            <Route path="/dashboard/truck" element={<DashboardTruck />} exact />
            {/* <Route path="/dashboard/kpi" element={<DashboardKPI />} exact /> */}
            <Route path="/truck" element={<Truck />} exact />
            <Route path="/truck/:id" element={<TruckInfo />} exact />
            <Route
              path="/user/:user_id/profile/:profile_id/truck-in-profile"
              element={<Truck />}
              exact
            />
            <Route
              path="/user/:user_id/profile/:profile_id/truck-in-profile/detail/:id"
              element={<TruckInfo />}
              exact
            />
            <Route
              path="/user/:user_id/profile/:profile_id/truck-in-profile/:truck_id/truck-check-project"
              element={<TruckCheckProject />}
              exact
            />
            <Route
              path="/user/:user_id/profile/:profile_id/truck-in-profile/:truck_id/truck-check-project/detail/:id"
              element={<TruckCheckProjectInfo />}
              exact
            />
            <Route
              path="/user/:user_id/profile/:profile_id/shipper-favorite-group"
              element={<FavoriteShipperGroup />}
              exact
            />
            <Route
              path="/user/:user_id/profile/:profile_id/shipper-favorite-group/detail/:id"
              element={<FavoriteShipperGroupInfo />}
              exact
            />
            <Route
              path="/user/:user_id/profile/:profile_id/shipper-favorite-group/:shipper_favorite_group_id/carrier-in-favorite-group"
              element={<CarrierInFavoriteGroup />}
              exact
            />
            <Route
              path="/user/:user_id/profile/:profile_id/shipper-favorite-group/:shipper_favorite_group_id/carrier-in-favorite-group/:carrier_in_favorite_group_id"
              element={<CarrierInFavoriteGroupInfo />}
              exact
            />
            <Route
              path="/user/:user_id/profile/:profile_id/profile-receipt-name"
              element={<ProfileReceiptName />}
              exact
            />
            <Route
              path="/user/:user_id/profile/:profile_id/profile-receipt-name/detail/:profile_receipt_name_id"
              element={<ProfileReceiptNameInfo />}
              exact
            />
            <Route
              // path="/user/:user_id/profile-join-carrier/:profile_id/participate-project"
              path="/user/:user_id/profile/:profile_id/participate-project"
              element={<ParticipateProject />}
              exact
            />
            <Route
              // path="/user/:user_id/profile-join-carrier/:profile_id/participate-project/detail/:participate_project_id"
              path="/user/:user_id/profile/:profile_id/participate-project/detail/:participate_project_id"
              element={<ParticipateProjectInfo />}
              exact
            />
            <Route
              path="/user/:user_id/profile/:profile_id/participate-project/:participate_project_id/project/:project_id/fuel-rate-quotation"
              element={<FuelRateQuotation />}
              exact
            />
            <Route
              path="/user/:user_id/profile/:profile_id/participate-project/:participate_project_id/project/:project_id/fuel-rate-quotation/detail/:fuel_rate_quotation_id"
              element={<FuelRateQuotationInfo />}
              exact
            />
            <Route
              path="/user/:user_id/profile/:profile_id/participate-runner-project"
              element={<ParticipateRunnerProject />}
              exact
            />
            <Route
              path="/user/:user_id/profile/:profile_id/participate-runner-project/detail/:id"
              element={<ProfileJoinCarrierInfo />}
              exact
            />
            <Route
              path="/user/:user_id/profile/:profile_id/co-runner-project"
              element={<CoRunnerProject />}
              exact
            />
            <Route
              path="/user/:user_id/profile/:profile_id/co-runner-project/detail/:id"
              element={<ProfileJoinCarrierInfo />}
              exact
            />
            <Route
              path="/user/:user_id/driver-traning-project/"
              element={<DriverTrainingProject />}
              exact
            />
            <Route
              path="/user/:user_id/driver-traning-project/detail/:id"
              element={<DriverTrainingProjectInfo />}
              exact
            />
            <Route
              path="/user/:user_id/driver-health-project/"
              element={<DriverHealthProject />}
              exact
            />
            <Route
              path="/user/:user_id/driver-health-project/detail/:id"
              element={<DriverHealthProjectInfo />}
              exact
            />
            <Route
              path="/truck/:truck_id/truck-check-project"
              element={<TruckCheckProject />}
              exact
            />
            <Route
              path="/truck/:truck_id/truck-check-project/detail/:id"
              element={<TruckCheckProjectInfo />}
              exact
            />
            <Route
              path="/master-data/term-and-condition"
              element={<TermAndCondition />}
              exact
            />
            <Route
              path="/master-data/term-and-condition/:id"
              element={<TermAndConditionInfo />}
              exact
            />
            <Route
              path="/master-data/privacy-policy"
              element={<PrivacyPolicy />}
              exact
            />
            <Route
              path="/master-data/privacy-policy/:id"
              element={<PrivacyPolicyInfo />}
              exact
            />
            <Route
              path="/master-data/service-area"
              element={<ServiceArea />}
              exact
            />
            <Route
              path="/master-data/service-area/:id"
              element={<ServiceAreaInfo />}
              exact
            />
            <Route
              path="/master-data/route-province"
              element={<RouteProvince />}
              exact
            />
            <Route
              path="/master-data/route-province/:id"
              element={<RouteProvinceInfo />}
              exact
            />
            <Route
              path="/master-data/route-stl-standard-size"
              element={<RouteStlStandardSize />}
              exact
            />
            <Route
              path="/master-data/route-stl-standard-size/:id"
              element={<RouteStlStandardSizeInfo />}
              exact
            />
            <Route
              path="/master-data/truck-type"
              element={<TruckType />}
              exact
            />
            <Route
              path="/master-data/truck-type/:id"
              element={<TruckTypeInfo />}
              exact
            />
            <Route
              path="/master-data/truck-type/order-list"
              element={<OrderTruckType />}
              exact
            />
            <Route
              path="/master-data/app-version/wemovedrive-version"
              element={<WemovedriveVersion />}
              exact
            />
            <Route
              path="/master-data/app-version/wemovedrive-version/:id"
              element={<WemovedriveVersionInfo />}
              exact
            />
            <Route
              path="/master-data/app-version/wemove-version"
              element={<WemoveVersion />}
              exact
            />
            <Route
              path="/master-data/app-version/wemove-version/:id"
              element={<WemoveVersionInfo />}
              exact
            />
            <Route path="/master-data/fuel-rate" element={<FuelRate />} exact />
            <Route
              path="/master-data/fuel-rate/:id"
              element={<FuelRateInfo />}
              exact
            />
            <Route
              path="/master-data/annual-insurance"
              element={<AnnualInsurance />}
              exact
            />
            <Route
              path="/master-data/accessories"
              element={<TruckAccessory />}
              exact
            />
            <Route
              path="/master-data/accessories/:id"
              element={<TruckAccessoryInfo />}
              exact
            />
            <Route
              path="/master-data/product-type"
              element={<ProductType />}
              exact
            />
            <Route
              path="/master-data/product-type/:id"
              element={<ProductTypeInfo />}
              exact
            />
            <Route
              path="/master-data/product-type/order-list"
              element={<OrderProductType />}
              exact
            />
            <Route
              path="/master-data/third-party-key"
              element={<ThirdPartyKey />}
              exact
            />
            <Route
              path="/master-data/product-trade-type"
              element={<TableProductTradeType />}
              exact
            />
            <Route
              path="/master-data/industry"
              element={<TableIndustry />}
              exact
            />
            <Route
              path="/master-data/product-rental-type"
              element={<TableProductRentalType />}
              exact
            />
            <Route path="/admin" element={<TableAdmin />} exact />
            <Route path="/admin/:id" element={<DetailAdmin />} exact />
            <Route
              path="/finance/shipper/unpaid"
              element={<ShipperUnpiad />}
              exact
            />
            <Route
              path="/finance/shipper/invoice"
              element={<ShipperInvoice />}
              exact
            />
            <Route
              path="/finance/shipper/invoice-group"
              element={<ShipperInvoiceGroup />}
              exact
            />
            <Route
              path="/finance/shipper/billingnote"
              element={<ShipperBillingnote />}
              exact
            />
            <Route
              path="/finance/shipper/billingnote-group"
              element={<ShipperBillingNoteGroup />}
              exact
            />
            <Route
              path="/finance/shipper/paid"
              element={<ShipperReceipt />}
              exact
            />
            <Route
              path="/finance/shipper/paid-group"
              element={<ShipperReceiptGroup />}
              exact
            />
            <Route
              path="/finance/carrier/unpaid"
              element={<CarrierUnpiad />}
              exact
            />
            <Route
              path="/finance/carrier/invoice"
              element={<CarrierInvoice />}
              exact
            />
            <Route
              path="/finance/carrier/invoice-group"
              element={<CarrierInvoiceGroup />}
              exact
            />
            <Route
              path="/finance/carrier/billingnote"
              element={<CarrierBillingnote />}
              exact
            />
            <Route
              path="/finance/carrier/billingnote-group"
              element={<CarrierBillingNoteGroup />}
              exact
            />
            <Route
              path="/finance/carrier/paid"
              element={<CarrierReceipt />}
              exact
            />
            <Route
              path="/finance/carrier/paid-group"
              element={<CarrierReceiptGroup />}
              exact
            />
            <Route path="/finance/tps/unpaid" element={<TpsUnpiad />} exact />
            <Route path="/finance/tps/invoice" element={<TpsInvoice />} exact />
            <Route
              path="/finance/tps/invoice-group"
              element={<TpsInvoiceGroup />}
              exact
            />
            <Route path="/export-data" element={<ExportData />} exact />
            <Route path="/post-work" element={<PostWork />} exact />
            <Route path="/post-work/:id" element={<PostWork />} exact />
            <Route path="/import-work" element={<ImportWork />} exact />
            <Route path="/route-optimize" element={<RouteOptimize />} exact />
            <Route path="/work" element={<AllWork />} exact />
            <Route path="/work-details/:id" element={<WorkDetailV2 />} exact />
            <Route path="/work-tracking" element={<TrackingWork />} exact />
            <Route path="/tracking-driver" element={<TrackingDriver />} exact />
            <Route path="/work-regular" element={<TableWorkRegular />} exact />
            <Route
              path="/work-regular/detail/:id"
              element={<WorkRegularDetail />}
              exact
            />
            <Route path="/trade" element={<TableTrade />} exact />
            <Route path="/trade/customer" element={<TableCustomer />} exact />
            <Route
              path="/trade/customer/:id"
              element={<TableProjectCustomer />}
              exact
            />
            <Route path="/trade/jobber" element={<TableJobber />} exact />
            <Route path="/trade-finance" element={<TradeBuyerUnpiad />} exact />
            <Route
              path="/trade-finance/buyer/invoice"
              element={<TradeBuyerInvoice />}
              exact
            />
            <Route
              path="/trade-finance/buyer/invoice-group"
              element={<TradeBuyerInvoiceGroup />}
              exact
            />
            <Route
              path="/trade-finance/buyer/billingnote"
              element={<TradeBuyerBillingNote />}
              exact
            />
            <Route
              path="/trade-finance/buyer/billingnote-group"
              element={<TradeBuyerBillingNoteGroup />}
              exact
            />
            <Route
              path="/trade-finance/buyer/paid"
              element={<TradeBuyerPay />}
              exact
            />
            <Route
              path="/trade-finance/buyer/paid-group"
              element={<TradeBuyerPayGroup />}
              exact
            />
            <Route
              path="/trade-finance/seller/unpaid"
              element={<TradeSellerUnpiad />}
              exact
            />
            <Route
              path="/trade-finance/seller/invoice"
              element={<TradeSellerInvoice />}
              exact
            />
            <Route
              path="/trade-finance/seller/invoice-group"
              element={<TradeSellerInvoiceGroup />}
              exact
            />
            <Route
              path="/trade-finance/seller/billingnote"
              element={<TradeSellerBillingNote />}
              exact
            />
            <Route
              path="/trade-finance/seller/billingnote-group"
              element={<TradeSellerBillingNoteGroup />}
              exact
            />
            <Route
              path="/trade-finance/seller/paid"
              element={<TradeSellerPay />}
              exact
            />
            <Route
              path="/trade-finance/seller/paid-group"
              element={<TradeSellerPayGroup />}
              exact
            />
            <Route
              path="/trade-finance/carrier/unpaid"
              element={<TradeCarrierUnpiad />}
              exact
            />
            <Route
              path="/trade-finance/carrier/invoice"
              element={<TradeCarrierInvoice />}
              exact
            />
            <Route
              path="/trade-finance/carrier/invoice-group"
              element={<TradeCarrierInvoiceGroup />}
              exact
            />
            <Route
              path="/trade-finance/carrier/billingnote"
              element={<TradeCarrierBillingNote />}
              exact
            />
            <Route
              path="/trade-finance/carrier/billingnote-group"
              element={<TradeCarrierBillingNoteGroup />}
              exact
            />
            <Route
              path="/trade-finance/carrier/paid"
              element={<TradeCarrierPay />}
              exact
            />
            <Route
              path="/trade-finance/carrier/paid-group"
              element={<TradeCarrierPayGroup />}
              exact
            />
            <Route
              path="/trade-finance/buyer/edit-transaction"
              element={<EditTransactionBuyer />}
              exact
            />
            <Route
              path="/trade-finance/seller/edit-transaction"
              element={<EditTransactionSeller />}
              exact
            />
            <Route
              path="/trade-finance/carrier/edit-transaction"
              element={<EditTransactionCarrier />}
              exact
            />
            <Route
              path="/truck-rental/tenant"
              element={<TableTenant />}
              exact
            />
            <Route
              path="/truck-rental/tenant/:id"
              element={<TableProjectTenant />}
              exact
            />
            <Route path="truck-rental/lessor" element={<TableLessor />} exact />
            <Route
              path="truck-rental/rental-order"
              element={<TableRental />}
              exact
            />
            <Route
              path="/truck-rental/finance/tenant/unpaid"
              element={<TruckTenantUnpiad />}
              exact
            />
            <Route
              path="/truck-rental/finance/tenant/invoice"
              element={<TruckTenantInvoice />}
              exact
            />
            <Route
              path="/truck-rental/finance/tenant/invoice-group"
              element={<TruckTenantInvoiceGroup />}
              exact
            />
            <Route
              path="/truck-rental/finance/tenant/billingnote"
              element={<TruckTenantBillingNote />}
              exact
            />
            <Route
              path="/truck-rental/finance/tenant/billingnote-group"
              element={<TruckTenantBillingNoteGroup />}
              exact
            />
            <Route
              path="/truck-rental/finance/tenant/paid"
              element={<TruckTenantPay />}
              exact
            />
            <Route
              path="/truck-rental/finance/tenant/paid-group"
              element={<TruckTenantPayGroup />}
              exact
            />
            <Route
              path="/truck-rental/finance/lessor/unpaid"
              element={<TruckLessorUnpiad />}
              exact
            />
            <Route
              path="/truck-rental/finance/lessor/invoice"
              element={<TruckLessorInvoice />}
              exact
            />
            <Route
              path="/truck-rental/finance/lessor/invoice-group"
              element={<TruckLessorInvoiceGroup />}
              exact
            />
            <Route
              path="/truck-rental/finance/lessor/billingnote"
              element={<TruckLessorBillingNote />}
              exact
            />
            <Route
              path="/truck-rental/finance/lessor/billingnote-group"
              element={<TruckLessorBillingNoteGroup />}
              exact
            />
            <Route
              path="/truck-rental/finance/lessor/paid"
              element={<TruckLessorPay />}
              exact
            />
            <Route
              path="/truck-rental/finance/lessor/paid-group"
              element={<TruckLessorPayGroup />}
              exact
            />
            <Route
              path="/truck-rental/finance/lessor/edit-transaction"
              element={<EditTransactionLessor />}
              exact
            />
            <Route
              path="/truck-rental/finance/tenant/edit-transaction"
              element={<EditTransactionTenant />}
              exact
            />
            <Route
              path="/finance/shipper/edit-transaction"
              element={<EditWorkTransactionShipper />}
              exact
            />
            <Route
              path="/finance/carrier/edit-transaction"
              element={<EditWorkTransactionCarrier />}
              exact
            />
            <Route path="/task" element={<TableTask />} exact />
            <Route path="/task/task-group" element={<TableTaskGroup />} exact />
            <Route path="/task/all-task" element={<TableTaskAll />} exact />
          </Route>

          <Route path="/login" element={<Login />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route
            path="/public/tracking/:id_secret"
            element={<WorkPublicDetail />}
          />
          <Route path="/public/work" element={<CountWorkByTruck />} />
          <Route
            path="/public/work/card/:truck_id"
            element={<PublicWorkCard />}
          />
          <Route path="/check-price" element={<CheckWorkPrice />} />
          <Route path="/check-app/:wid" element={<CheckApp />} />
          <Route path="/check-oil-price" element={<CheckOilPrice />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
